import Image from "next/image";
import Link from "next/link";
import { styled } from "@mui/material";
import AspectRatioContainer from "@common/AspectRatioContainer";
import IconButton from "@common/Buttons/IconButton";
import { BUTTON_SIZE, VARIANT } from "@constants/button";
import { IconEnum, SCALE_ANIMATION } from "@constants/consts";
import { colors, fonts, gradients } from "@constants/cssVariables";
import { TEALIUM_EVENT_LABEL } from "@constants/tealium.constants";
import { zIndex } from "@constants/zIndex";
import trackContentClick from "@utils/common/trackContentClick";

const firstChildSelector = "& > div:nth-of-type(1)";
const nonFirstChildSelector = "& > div:nth-of-type(n + 2)";

const GalleryContainer = styled("div")(({ theme }) => ({
  display: "grid",
  gridTemplateColumns: "repeat(6, 1fr)",
  gridGap: theme.spacing(4),
  marginBottom: theme.spacing(8),

  [`${firstChildSelector}`]: {
    gridColumn: "span 4",
    gridRow: "span 4",

    [`${Title}`]: {
      font: fonts.header2,
    },
  },
  [`${nonFirstChildSelector}`]: {
    gridColumn: "span 2",
    gridRow: "span 2",
  },

  [theme.breakpoints.down("md")]: {
    [`${firstChildSelector}`]: {
      marginLeft: `-${theme.spacing(4)}`,
      marginRight: `-${theme.spacing(4)}`,

      [`${AspectRatio}`]: {
        borderRadius: 0,
      },
    },

    [`${nonFirstChildSelector}`]: {
      [`${Title}`]: {
        position: "inherit",
        color: colors.neutral80,
        marginTop: theme.spacing(2),
      },

      "button:has(svg)": {
        width: 28,
        height: 28,
        right: theme.spacing(2),
        bottom: theme.spacing(2),
      },

      "div > svg": {
        width: 16,
        height: 16,
      },
    },
  },

  [theme.breakpoints.down("lg")]: {
    [`${firstChildSelector}`]: {
      gridColumn: "span 6",
    },
    [`${nonFirstChildSelector}`]: {
      gridColumn: "span 3",
    },
  },

  [theme.breakpoints.up("md")]: {
    marginTop: theme.spacing(8),
  },

  [theme.breakpoints.up("lg")]: {
    [`${firstChildSelector}`]: {
      [`${AspectRatio}`]: {
        height: "100%",
        paddingTop: 0,
      },
    },
  },
}));

const AspectRatio = styled(AspectRatioContainer)(({ theme }) => ({
  borderRadius: 8,
  overflow: "hidden",

  "&:after": {
    content: '""' as string,
    position: "absolute",
    borderRadius: 8,
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    zIndex: zIndex.base,
    background: gradients.fadeBlackBottomTop,
  },

  [theme.breakpoints.down("md")]: {
    borderRadius: 6,
    "&:after": {
      borderRadius: 6,
    },
  },
}));

const PlayIcon = styled(IconButton)(({ theme }) => ({
  position: "absolute",
  right: theme.spacing(4),
  bottom: theme.spacing(6),
  zIndex: zIndex.baseControls,
}));

const Tile = styled("div")({
  position: "relative",
  ...SCALE_ANIMATION,
});

const Title = styled("p")<{ hasVideo: boolean }>(({ theme, hasVideo }) => ({
  position: "absolute",
  zIndex: zIndex.base,
  left: theme.spacing(4),
  right: theme.spacing(4),
  bottom: theme.spacing(6),
  font: fonts.header4,
  color: colors.neutral0,
  display: "-webkit-box",
  WebkitBoxOrient: "vertical",
  WebkitLineClamp: 3,
  overflow: "hidden",
  textOverflow: "ellipsis",

  [theme.breakpoints.down("md")]: {
    font: fonts.header6,
  },
  ...(hasVideo && { right: `calc(2 * ${theme.spacing(4)} + 40px)` }),
}));

export const playButtonTestId = "gallery-header-play-button";

const GalleryHeader: React.FC<{ items: Array<PageLink> }> = ({ items }) => {
  return (
    <GalleryContainer>
      {items.map((item, index) => (
        <Tile key={item.id}>
          <Link
            href={item.url}
            target={item.target}
            onClick={() =>
              trackContentClick({
                position: index + 1,
                label: TEALIUM_EVENT_LABEL.HEADER_PROMO,
                ...item,
              })
            }
          >
            <AspectRatio aspectRatio="16:9">
              <Image
                src={item.imageUrl}
                alt={item.title}
                fill
                priority
                sizes={`(max-width: 900px) ${index === 0 ? 100 : 50}vw, ${index === 0 ? 66 : 33}vw`}
              />
              {item.hasVideo && (
                <PlayIcon
                  icon={IconEnum.PLAY_ICON_FILLED}
                  size={BUTTON_SIZE.MEDIUM}
                  variant={VARIANT.PRIMARY}
                  color={colors.neutral0}
                  isNonInteractive
                  data-testid={playButtonTestId}
                />
              )}
            </AspectRatio>
            <Title hasVideo={item.hasVideo}>{item.title}</Title>
          </Link>
        </Tile>
      ))}
    </GalleryContainer>
  );
};

export default GalleryHeader;
