import { useEffect, useState } from "react";
import Tealium from "@4tn/webx-analytics/lib/Tealium";
import { styled } from "@mui/material";
import TopAndTabBarContainer from "@common/TopAndTabBarContainer";
import PageContainer from "@pageContent/common/PageContainer";
import { ScreenSizes } from "@constants/consts";
import { colors } from "@constants/cssVariables";
import { zIndex } from "@constants/zIndex";
import useScreenSize from "@utils/common/useScreenSize";
import SearchModalResult from "./components/SearchModalResult";
import SearchModalTabBar from "./components/SearchModalTabBar";
import SearchModalTopBar from "./components/SearchModalTopBar";
import useSearchValues from "./useSearchValues";

const SearchModalContainer = styled("div")(({ theme }) => ({
  zIndex: zIndex.modal,
  position: "fixed",
  top: 0,
  left: 0,
  width: "100%",
  height: "100%",
  padding: theme.spacing(20, 0, 30),
  backgroundColor: colors.neutral0,
  overflow: "auto",
  [theme.breakpoints.up("lg")]: {
    scrollbarGutter: "stable",
  },
}));

const SearchModal: React.FC = () => {
  const { isModalOpen, searchValue, tabValue } = useSearchValues();
  const screenSize = useScreenSize();

  const [hasResults, setHasResults] = useState(false);
  const [shouldAnimateTabBar, setShouldAnimateTabBar] = useState(false);

  useEffect(() => {
    setShouldAnimateTabBar((prevState) => {
      if (screenSize === ScreenSizes.sm || screenSize === ScreenSizes.md) return false;
      if (isModalOpen && prevState && hasResults) return false;
      if (!isModalOpen && !prevState) return true;
      return prevState;
    });
  }, [isModalOpen, screenSize, hasResults]);

  useEffect(() => {
    if (!isModalOpen) {
      Tealium.setDataLayer({ search_keyword: null, search_results: null });
    }
    document.documentElement.style.setProperty("overflow-y", isModalOpen ? "hidden" : "auto"); // Prevents background scrolling when the modal is open
  }, [isModalOpen]);

  useEffect(() => {
    const onSearchResults = (data: WebXEventParams["searchResults"]) => setHasResults(data.hasResults);
    window.eventBus.on("searchResults", onSearchResults);

    return () => window.eventBus.off("searchResults", onSearchResults);
  }, []);

  if (!isModalOpen) {
    return null;
  }

  const searchTermHasResults = !!(searchValue && (hasResults || tabValue));

  return (
    <SearchModalContainer>
      <TopAndTabBarContainer>
        <SearchModalTopBar />
        {(searchTermHasResults || shouldAnimateTabBar) && (
          <SearchModalTabBar shouldAnimateTabBar={shouldAnimateTabBar} searchTermHasResults={searchTermHasResults} />
        )}
      </TopAndTabBarContainer>
      <PageContainer>
        <SearchModalResult />
      </PageContainer>
    </SearchModalContainer>
  );
};

export default SearchModal;
