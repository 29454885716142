import React from "react";
import Link from "next/link";
import Tealium from "@4tn/webx-analytics";
import { styled } from "@mui/material";
import { useInfiniteQuery } from "@tanstack/react-query";
import { ButtonContainer, PrimaryButton } from "@common/Buttons";
import { TEALIUM_EVENT_CATEGORY, TEALIUM_EVENT_NAME } from "@host/constants/tealium.constants";
import { SHOW_MORE } from "@constants/consts";
import { colors, fonts } from "@constants/cssVariables";
import { useNoticeError } from "@utils/common/newRelic";
import { paginatedFetch } from "@utils/pageContent/fetchData";
import InfiniteCollectionSkeleton, { CollectionSkeleton } from "../Skeletons/InfiniteCollectionSkeleton";
import InfiniteCollectionItem from "./InfiniteCollectionItem";

export const InfiniteCollectionContainer = styled("div")(({ theme }) => ({
  margin: theme.spacing(8, 0),
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  gap: theme.spacing(4),

  [`${ButtonContainer}`]: {
    marginTop: theme.spacing(2),
  },

  [theme.breakpoints.up("sm")]: {
    margin: theme.spacing(8, 0),
  },

  [theme.breakpoints.up("md")]: {
    margin: theme.spacing(10, 0),
  },
}));

export const TitleWrapper = styled("div")(({}) => ({
  display: "flex",
  width: "100%",
  justifyContent: "space-between",
  alignItems: "end",
}));

export const Title = styled("h3")(({}) => ({
  font: fonts.header3,
  color: colors.neutral90,
  alignSelf: "flex-start",
}));

export const ShowAllLink = styled(Link)(({ theme }) => ({
  font: fonts.buttonSmall,
  color: colors.primary,
  [theme.breakpoints.up("md")]: {
    font: fonts.buttonMedium,
  },
}));

export const ItemsContainer = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  gap: theme.spacing(4),
  alignSelf: "flex-start",
  width: "100%",

  [theme.breakpoints.up("sm")]: {
    display: "grid",
    gap: theme.spacing(6, 4),
    gridTemplateColumns: "repeat(2, minmax(0, 1fr))",
    alignItems: "stretch",
  },
  [theme.breakpoints.up("md")]: {
    gridTemplateColumns: "repeat(3, minmax(0, 1fr))",
  },
  [theme.breakpoints.up("lg")]: {
    gridTemplateColumns: "repeat(4, minmax(0, 1fr))",
  },
}));

interface InfiniteCollectionProps {
  title: string;
  endpoint: string;
  limit: number;
  collectionName: string;
  showCategory?: boolean;
  showAllText?: string;
  showAllUrl?: string;
  enlargeFirstItem?: boolean;
}

const InfiniteCollection: React.FC<InfiniteCollectionProps> = ({
  title,
  endpoint,
  limit,
  collectionName,
  showCategory,
  showAllText,
  showAllUrl,
}) => {
  const infiniteCollectionQueryKey = [collectionName, endpoint, limit];

  const {
    data: links,
    isLoading,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    error,
  } = useInfiniteQuery({
    queryKey: infiniteCollectionQueryKey,
    queryFn: ({ pageParam: page }) => paginatedFetch<PageLink>({ endpoint, limit, page }),
    initialPageParam: 0,
    select: (data) => data.pages.flatMap((pages) => pages.items),
    getNextPageParam: (lastPage) => lastPage.nextPage,
  });

  useNoticeError(error, {
    queryKey: infiniteCollectionQueryKey.join(","),
    collectionName,
    endpoint,
    limit,
    title,
  });

  const loadMore = () => {
    fetchNextPage();
    Tealium.link({
      event_category: TEALIUM_EVENT_CATEGORY.CONTENT_LISTING,
      event_name: TEALIUM_EVENT_NAME.CONTENT_LISTING_MORE,
      event_label: title,
    });
  };

  if (isLoading && !links) {
    return (
      <InfiniteCollectionSkeleton title={title} limit={limit} showCategory={showCategory} showAllText={showAllText} />
    );
  }

  if (!links?.length) return null;

  return (
    <InfiniteCollectionContainer>
      <TitleWrapper>
        <Title>{title}</Title>
        {showAllText && showAllUrl && <ShowAllLink href={showAllUrl}>{showAllText}</ShowAllLink>}
      </TitleWrapper>
      <ItemsContainer>
        {links.map((link, index) => (
          <InfiniteCollectionItem
            key={link.id}
            position={index + 1}
            sectionTitle={title}
            link={link}
            showCategory={showCategory}
          />
        ))}
        {isFetchingNextPage && <CollectionSkeleton title={title} limit={limit} showCategory={showCategory} />}
      </ItemsContainer>
      {hasNextPage && (
        <PrimaryButton fullWidth isLoading={isFetchingNextPage} onClick={loadMore}>
          {SHOW_MORE}
        </PrimaryButton>
      )}
    </InfiniteCollectionContainer>
  );
};

export default InfiniteCollection;
