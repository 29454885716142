import React, { Fragment } from "react";
import Link from "next/link";
import { styled } from "@mui/material";
import { useInfiniteQuery } from "@tanstack/react-query";
import { ButtonContainer, PrimaryButton } from "@common/Buttons";
import * as StyledOopsPage from "@pageContent/OopsPage/OopsPage.styled";
import InfiniteCollectionSkeleton, {
  CollectionSkeleton,
} from "@pageContent/common/Skeletons/InfiniteCollectionSkeleton";
import { NO_CATEGORY_ITEM, SHOW_MORE, TO_THE_HOME_PAGE, errorFallback } from "@constants/consts";
import { useNoticeError } from "@utils/common/newRelic";
import { getDateLabel } from "@utils/pageContent/dateLabel";
import { paginatedFetch } from "@utils/pageContent/fetchData";
import useFetchLimit from "@utils/pageContent/useFetchLimit";
import { InfiniteCollectionContainer, ItemsContainer } from "../common/InfiniteCollection/InfiniteCollection";
import InfiniteCollectionItem from "../common/InfiniteCollection/InfiniteCollectionItem";

export const Items = styled(ItemsContainer)(({ theme }) => ({
  [theme.breakpoints.up("sm")]: {
    h5: {
      gridColumn: "1 / -1",
      margin: theme.spacing(-2, 0),
    },
  },
}));

export const NoItemsContainer = styled(StyledOopsPage.TextContainer)(({ theme }) => ({
  margin: theme.spacing(8, 0, 20),

  [`${ButtonContainer}`]: {
    marginTop: theme.spacing(2),
    [theme.breakpoints.up("md")]: {
      marginTop: theme.spacing(4),
    },
  },

  [theme.breakpoints.up("xl")]: {
    margin: theme.spacing(33, 0),
    justifyContent: "flex-start",
  },
}));

const CreatedDate = styled("h5")({
  font: "var(--font-header-5)",
  color: "var(--neutral-40-color)",
});

const initialFetchedItemsNumber = {
  xs: 16,
  sm: 18,
  md: 18,
  lg: 16,
};

const categoryQueryKey = "category-collection-divided-by-date";

const CategoryCollectionDividedByDate: React.FC<{
  title: string;
  articleEndpoint: string;
  showCategory: boolean;
}> = ({ title, articleEndpoint, showCategory }) => {
  const limit = useFetchLimit(initialFetchedItemsNumber);
  const categoryCollectionDividedByDateQueryKey = [categoryQueryKey, articleEndpoint, limit];
  const {
    data: items,
    isLoading,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    error,
    isError,
  } = useInfiniteQuery({
    queryKey: categoryCollectionDividedByDateQueryKey,
    queryFn: ({ pageParam: page }) =>
      paginatedFetch<PageLink>({ endpoint: articleEndpoint, limit, page }).then((result) => ({
        ...result,
        items: result.items.map((item) => ({
          ...item,
          creationDate: item.createdAt && getDateLabel(item.createdAt, { showDay: false, showTime: false }),
        })) as Array<PageLink & { creationDate?: string }>,
      })),
    initialPageParam: 0,
    select: (data) => data.pages.flatMap((pages) => pages.items),
    getNextPageParam: (lastPage) => lastPage.nextPage,
  });

  useNoticeError(error, {
    queryKey: categoryCollectionDividedByDateQueryKey.join(","),
    categoryQueryKey,
    articleEndpoint,
    limit,
    title,
  });

  if (isLoading && !items) {
    return <InfiniteCollectionSkeleton title={title} limit={limit} showCategory={showCategory} />;
  }

  if (!items?.length)
    return (
      <NoItemsContainer>
        <StyledOopsPage.HeaderText>
          {isError ? errorFallback.headerText : NO_CATEGORY_ITEM.replace("CATEGORY", title)}
        </StyledOopsPage.HeaderText>
        {isError && <StyledOopsPage.DescriptionText>{errorFallback.bodyText}</StyledOopsPage.DescriptionText>}
        <PrimaryButton component={Link} fullWidth href={"/"}>
          {TO_THE_HOME_PAGE}
        </PrimaryButton>
      </NoItemsContainer>
    );

  return (
    <InfiniteCollectionContainer>
      <Items>
        {items.map((item, index) => (
          <Fragment key={item.id}>
            {(!items[index - 1] || items[index - 1].creationDate !== item.creationDate) && (
              <CreatedDate>{item.creationDate}</CreatedDate>
            )}
            <InfiniteCollectionItem position={index + 1} sectionTitle={title} link={item} showCategory={showCategory} />
          </Fragment>
        ))}
        {isFetchingNextPage && <CollectionSkeleton title={title} limit={limit} showCategory={showCategory} />}
      </Items>
      {hasNextPage && (
        <PrimaryButton fullWidth disabled={isFetchingNextPage} onClick={() => fetchNextPage()}>
          {SHOW_MORE}
        </PrimaryButton>
      )}
    </InfiniteCollectionContainer>
  );
};

export default CategoryCollectionDividedByDate;
