import { useMemo } from "react";
import { GlobalStyles, ThemeProvider, createTheme } from "@mui/material";
import { FooterWrapper } from "@navigation/Footer/Footer.styled";
import ContentContainer from "@common/ContentContainer/ContentContainer";
import TopAndTabBarContainer from "@common/TopAndTabBarContainer";

export const globalStyles = (
  <GlobalStyles
    styles={(theme) => ({
      body: {
        paddingTop: 112,
        [theme.breakpoints.up("md")]: {
          paddingTop: 120,
        },
        "&.hamburger-menu-open": {
          [`${ContentContainer}, ${TopAndTabBarContainer}, ${FooterWrapper}`]: {
            transform: "translateX(320px)",

            [theme.breakpoints.up("md")]: {
              transform: "translateX(400px)",
            },
          },
        },
      },
    })}
  />
);

const Theme: React.FC<{ children: React.ReactNode; designTokens?: DesignTokens }> = ({ children, designTokens }) => {
  const theme = useMemo(
    () =>
      createTheme({
        breakpoints: {
          values: {
            xs: 0,
            sm: 600,
            md: 900,
            lg: 1200,
            xl: 1440,
          },
        },
        spacing: 4,
        palette: {
          primary: {
            // light?:
            main: designTokens?.colors.primary100 || "#000",
            // dark?: string;
            contrastText: designTokens?.colors.neutral0 || "#FFF",
          },
        },
      }),
    [designTokens]
  );
  return <ThemeProvider theme={theme}>{children}</ThemeProvider>;
};

export default Theme;
