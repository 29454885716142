import { FC } from "react";
import { styled } from "@mui/material";
import AspectRatioContainer from "@common/AspectRatioContainer";
import InfiniteCollection from "@pageContent/common/InfiniteCollection";
import { ItemsContainer } from "@pageContent/common/InfiniteCollection/InfiniteCollection";
import * as InfiniteCollectionItemStyled from "@pageContent/common/InfiniteCollection/InfiniteCollectionItem/InfiniteCollectionItem.styled";
import { TextContainer as SkeletonTextContainer } from "@pageContent/common/Skeletons/ArticleSkeleton";
import { colors, fonts } from "@constants/cssVariables";
import useFetchLimit from "@utils/pageContent/useFetchLimit";

const CategoryBasedCollectionWrapper = styled("div")<{ enlargeFirstItem: boolean }>(({ theme, enlargeFirstItem }) => ({
  ...(enlargeFirstItem && {
    [theme.breakpoints.down("sm")]: {
      [`${ItemsContainer} > ${InfiniteCollectionItemStyled.ItemContainer}:nth-of-type(1)`]: {
        display: "unset",
        position: "relative",
        [`${InfiniteCollectionItemStyled.ImageContainer}`]: {
          display: "unset",
          width: "100%",
          minWidth: "unset",
          height: "unset",
          position: "relative",
          [`${InfiniteCollectionItemStyled.PlayIcon}`]: {
            right: 16,
            bottom: 16,
            height: 40,
            width: 40,
            "div > svg": {
              height: 20,
              width: 20,
            },
          },
          [`${AspectRatioContainer}`]: {
            img: {
              borderRadius: 6,
            },
          },
        },
        [`${InfiniteCollectionItemStyled.ImageContainer} ${InfiniteCollectionItemStyled.TextLabel}`]: {
          top: 2,
          borderRadius: "4px 2px 2px 2px",
          [theme.breakpoints.up("sm")]: {
            top: "unset",
          },
        },
        [`${InfiniteCollectionItemStyled.ImageContainer}:has(> ${InfiniteCollectionItemStyled.PlayIcon}) + ${InfiniteCollectionItemStyled.TextContainer} p`]:
          {
            paddingRight: theme.spacing(14),
          },
        [`${InfiniteCollectionItemStyled.TextContainer}`]: {
          position: "absolute",
          bottom: 0,
          width: "100%",
          padding: theme.spacing(4),
          p: {
            width: "100%",
            font: fonts.header4,
            color: colors.neutral0,
          },
        },
      },

      [`${ItemsContainer} > div:first-child`]: {
        flexDirection: "column",
        width: "100%",
        height: "100%",
        span: {
          display: "block",
          width: "100%",
          height: "auto",
          maxWidth: "none",
          div: {
            width: "100%",
          },
        },

        [`${SkeletonTextContainer}`]: {
          padding: 0,
        },

        "div span": {
          display: "none",
          padding: 0,
        },
      },
    },
  }),
}));

const initialFetchedItemsNumber = {
  xs: 5,
  sm: 8,
  md: 6,
  lg: 8,
};

const collectionName = "category-based-collection";

interface CategoryBasedCollectionProps {
  title: string;
  endpoint: string;
  showAllText?: string;
  showAllUrl?: string;
  showCategory: boolean;
  enlargeFirstItem: boolean;
}

const CategoryBasedCollection: FC<CategoryBasedCollectionProps> = (props) => {
  const limit = useFetchLimit(initialFetchedItemsNumber);

  return (
    <CategoryBasedCollectionWrapper enlargeFirstItem={props.enlargeFirstItem}>
      <InfiniteCollection limit={limit} collectionName={collectionName} {...props} />
    </CategoryBasedCollectionWrapper>
  );
};

CategoryBasedCollection.defaultProps = {
  enlargeFirstItem: true,
};

export default CategoryBasedCollection;
