import Tealium from "@4tn/webx-analytics/lib/Tealium";
import { CONTENT_TYPE_LABEL, ROUTE_PATHS } from "@constants/consts";
import { TEALIUM_EVENT_CATEGORY, TEALIUM_EVENT_NAME } from "@constants/tealium.constants";
import { getDateLabel } from "./dateLabel";

export interface ReturnFetchData<T> {
  items: T[];
  totalResults: number;
  nextPage: number | null;
}

export interface PaginatedFetch {
  endpoint: string;
  limit: number;
  page: number;
  contentType?: string;
}

export async function paginatedFetch<T>(props: PaginatedFetch): Promise<ReturnFetchData<T>> {
  const { endpoint, limit, page, contentType } = props;
  const skip = page * limit;
  const hasQuery = endpoint.includes("?");
  const response = await fetch(
    `${endpoint}${hasQuery ? "&" : "?"}limit=${limit}&skip=${skip}${contentType ? `&contentType=${contentType}` : ""}`
  );
  const { data, message } = await response.json();
  if (typeof data === "undefined") throw new Error(message || "Failed to retrieve collection data");

  const totalItemsSoFar = page * limit + data.items.length;

  return {
    items: data.items,
    totalResults: data.total,
    nextPage: totalItemsSoFar >= data.total || data.items.length < limit ? null : page + 1,
  };
}

export async function fetchByEndpoint<T>(endpoint: string): Promise<T> {
  const response = await fetch(endpoint);
  const { data, message } = await response.json();
  if (typeof data === "undefined") throw new Error(message || `Failed to fetch from ${endpoint}`);
  return data;
}

interface FetchLinksProps {
  searchValue: string;
  contentApiEndpoint: string;
  page: number;
  contentType: string;
  limit: number;
  isModalSearch?: boolean;
}

export const fetchLinks = async (props: FetchLinksProps) => {
  const { searchValue, contentApiEndpoint, page, contentType, limit, isModalSearch } = props;
  const endpoint = `${contentApiEndpoint}/search/` + searchValue;

  const data = await paginatedFetch<PageLink>({ endpoint, limit, page, contentType });
  data.items = data.items.map((item) => ({
    ...item,
    createdAt: getDateLabel(item.createdAt || ""),
    contentTypeLabel:
      // Always set the label if it is sponsored content
      item.sponsor?.label ??
      // Otherwise set it only if we are on the "Alles" search modal tab
      (!contentType
        ? item.url.includes(ROUTE_PATHS.VIDEO.VIDEOS)
          ? CONTENT_TYPE_LABEL.VIDEO
          : CONTENT_TYPE_LABEL.ARTICLE
        : ""),
  }));

  if (page === 0 && isModalSearch) {
    // We don't want loading more search results to register as a separate search query
    Tealium.setDataLayer({ search_keyword: searchValue, search_results: data.totalResults });
    Tealium.link({
      event_name: TEALIUM_EVENT_NAME.SEARCH_QUERY,
      event_category: TEALIUM_EVENT_CATEGORY.SEARCH,
      event_label: searchValue,
    });
  }

  return data;
};
