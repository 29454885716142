import { FC, useEffect } from "react";
import Tealium from "@4tn/webx-analytics";
import useSearchValues from "@navigation/SearchModal/useSearchValues";
import { useInfiniteQuery } from "@tanstack/react-query";
import OopsPage from "@pageContent/OopsPage";
import { RESULTS_FOR } from "@constants/consts";
import { TEALIUM_EVENT_CATEGORY, TEALIUM_EVENT_LABEL, TEALIUM_EVENT_NAME } from "@constants/tealium.constants";
import { useHostConfig } from "@utils/common/getHostConfig";
import { useNoticeError } from "@utils/common/newRelic";
import { fetchLinks } from "@utils/pageContent/fetchData";
import NoSearchResult from "../NoSearchResult";
import SearchModalPlaceholder from "../SearchModalPlaceholder";
import { searchTabValues } from "../SearchModalTabBar/SearchModalTabBar";
import * as Styled from "./SearchModalResult.styled";
import SearchResultItems from "./SearchResultItems";

const trackClick = (position: number) => {
  Tealium.link({
    event_name: TEALIUM_EVENT_NAME.SEARCH_CLICK,
    event_category: TEALIUM_EVENT_CATEGORY.SEARCH,
    event_label: TEALIUM_EVENT_LABEL.SEARCH_RESULTS,
    event_value: position,
  });
};

const limit = 24;

const SearchModalResult: FC = () => {
  const { searchValue, tabValue } = useSearchValues();
  const tabContentType = searchTabValues[tabValue].contentType;
  const searchModalResultQueryKey = ["search", searchValue, tabContentType];
  const { contentApiEndpoint } = useHostConfig();

  const { data, isLoading, isError, fetchNextPage, hasNextPage, isFetchingNextPage, error } = useInfiniteQuery({
    queryKey: searchModalResultQueryKey,
    queryFn: ({ pageParam: page }) => {
      if (!searchValue)
        return {
          items: [],
          nextPage: limit,
          totalResults: 0,
        };
      return fetchLinks({
        searchValue,
        contentApiEndpoint,
        page,
        contentType: tabContentType,
        limit,
        isModalSearch: true,
      });
    },
    initialPageParam: 0,
    getNextPageParam: (lastPage) => lastPage.nextPage,
  });

  useNoticeError(error, { queryKey: searchModalResultQueryKey.join(","), searchValue, tabValue });

  const [firstPage] = data?.pages || [];
  useEffect(() => {
    window.eventBus.dispatch("searchResults", { hasResults: !!firstPage?.items.length });
  }, [firstPage]);

  const title = `${Number(firstPage?.totalResults) > 100 ? "100+" : firstPage?.totalResults} ${RESULTS_FOR} `;

  if (isLoading && !firstPage?.items.length)
    return <Styled.InfiniteCollectionSkeletonContainer title={title} limit={limit} showCategory showAllText={""} />;

  if (isError) return <OopsPage />;

  if (!searchValue) return <SearchModalPlaceholder />;

  if (searchValue && firstPage?.items.length === 0 && !isLoading) return <NoSearchResult currentTab={tabValue} />;

  return (
    <SearchResultItems
      title={title}
      searchValue={searchValue}
      data={data}
      isFetchingNextPage={isFetchingNextPage}
      hasNextPage={hasNextPage}
      isLoading={isLoading}
      limit={limit}
      fetchNextPage={fetchNextPage}
      trackClick={trackClick}
    />
  );
};

export default SearchModalResult;
