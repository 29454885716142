import React, { Fragment, ReactElement, useMemo } from "react";
import AdSlot from "@pageContent/AdSlot";
import SponsoredCard from "@pageContent/SponsoredCard";
import { AD_IDS } from "@constants/consts";
import { RootNode } from "@typings/richText";
import { documentToReactComponents } from "@utils/pageContent/richTextToJSX";
import { RichTextWrapper } from "./RichText.styled";
import RichTextParagraph from "./RichTextParagraph";

interface ComponentWithChildren {
  type?: React.FunctionComponent<{
    children: React.ReactNode;
  }>;
}

const richTextParagraphType = React.createElement(RichTextParagraph).type;

const RichText: React.FC<{ richText: RootNode; isSponsored?: boolean }> = ({ richText, isSponsored }) => {
  const items = useMemo(() => {
    return documentToReactComponents(richText).reduce(
      (acc, Item, index) => {
        const isParagraph = (Item as ComponentWithChildren)?.type === richTextParagraphType;
        let paragraphCount = acc.paragraphCount || 0;

        if (isParagraph) paragraphCount++;

        acc.elements.push(
          <Fragment key={index}>
            {index === 1 && isSponsored === false && <AdSlot id={AD_IDS.contentBanner} />}
            {Item}
            {paragraphCount === 3 && isSponsored === false && isParagraph && <SponsoredCard />}
          </Fragment>
        );

        return {
          elements: acc.elements,
          paragraphCount,
        };
      },
      { elements: [] as ReactElement[], paragraphCount: 0 }
    ).elements;
  }, [richText, isSponsored]);

  return <RichTextWrapper>{items}</RichTextWrapper>;
};

export default RichText;
