import { Fragment, ReactElement, ReactNode, cloneElement, isValidElement } from "react";
import Subscription from "@navigation/Subscription";
import Accordion, { StyledAccordion } from "@pageContent/Accordion";
import InlinePlayer from "@pageContent/ArticleInlinePlayer/InlinePlayer";
import HighlightedQuote from "@pageContent/HighlightedQuote";
import HtmlEmbed from "@pageContent/HtmlEmbed/HtmlEmbed";
import ImageEmbed from "@pageContent/ImageEmbed";
import LinkList from "@pageContent/LinkList";
import RichTextHeading from "@pageContent/RichText/RichTextHeading";
import RichTextHyperlink from "@pageContent/RichText/RichTextHyperlink";
import RichTextList from "@pageContent/RichText/RichTextList";
import RichTextListItem from "@pageContent/RichText/RichTextListItem";
import RichTextParagraph from "@pageContent/RichText/RichTextParagraph";
import RichTextTable from "@pageContent/RichText/RichTextTable";
import SocialEmbed from "@pageContent/SocialEmbed";
import TextFrame from "@pageContent/TextFrame";
import VideoCollection from "@pageContent/VideoCollection";
import { MarkType, RichText } from "@constants/richText";
import {
  AccordionNode,
  BlockNode,
  HtmlEmbedNode,
  HyperlinkNode,
  ImageEmbedNode,
  InlinePlayerNode,
  ListItemNode,
  ListListNode,
  ListNode,
  NestedRichTextNode,
  NewsletterEmbedNode,
  QuoteNode,
  RichTextNode,
  RootNode,
  SocialEmbedNode,
  TableNode,
  TextFrameNode,
  TextNode,
  VideoCollectionNode,
} from "@typings/richText";

type Node =
  | TextNode
  | ListNode
  | BlockNode
  | ListItemNode
  | HtmlEmbedNode
  | NewsletterEmbedNode
  | AccordionNode
  | TableNode
  | HyperlinkNode
  | TextNode;

interface NodeRenderer {
  (children: ReactNode, node: Node): ReactNode;
}

type SpecificNodeRenderer<T extends RichTextNode> = (children: React.ReactNode, node: T) => React.ReactNode;

interface NodeRenderers {
  [RichText.PARAGRAPH]: NodeRenderer;
  [RichText.HEADING_2]: NodeRenderer;
  [RichText.HEADING_3]: NodeRenderer;
  [RichText.HEADING_4]: NodeRenderer;
  [RichText.HEADING_5]: NodeRenderer;
  [RichText.HEADING_6]: NodeRenderer;
  [RichText.UL_LIST]: NodeRenderer;
  [RichText.OL_LIST]: NodeRenderer;
  [RichText.LIST_ITEM]: NodeRenderer;
  [RichText.HYPERLINK]: SpecificNodeRenderer<HyperlinkNode>;
  [RichText.QUOTE]: SpecificNodeRenderer<QuoteNode>;
  [RichText.TEXT_FRAME]: SpecificNodeRenderer<TextFrameNode>;
  [RichText.HTML_EMBED]: SpecificNodeRenderer<HtmlEmbedNode>;
  [RichText.NEWSLETTER]: SpecificNodeRenderer<NewsletterEmbedNode>;
  [RichText.IMAGE_EMBED]: SpecificNodeRenderer<ImageEmbedNode>;
  [RichText.LINK_LIST]: SpecificNodeRenderer<ListListNode>;
  [RichText.INLINE_PLAYER]: SpecificNodeRenderer<InlinePlayerNode>;
  [RichText.VIDEO_COLLECTION]: SpecificNodeRenderer<VideoCollectionNode>;
  [RichText.SOCIAL_EMBED]: SpecificNodeRenderer<SocialEmbedNode>;
  [RichText.RICH_TEXT]: SpecificNodeRenderer<NestedRichTextNode>;
  [RichText.TABLE]: SpecificNodeRenderer<TableNode>;
  [RichText.ACCORDION]: SpecificNodeRenderer<AccordionNode>;
}

const defaultNodeRenderers: NodeRenderers = {
  [RichText.PARAGRAPH]: (children) => <RichTextParagraph>{children}</RichTextParagraph>,
  [RichText.HEADING_2]: (children, node) => <RichTextHeading type={node.nodeType}>{children}</RichTextHeading>,
  [RichText.HEADING_3]: (children, node) => <RichTextHeading type={node.nodeType}>{children}</RichTextHeading>,
  [RichText.HEADING_4]: (children, node) => <RichTextHeading type={node.nodeType}>{children}</RichTextHeading>,
  [RichText.HEADING_5]: (children, node) => <RichTextHeading type={node.nodeType}>{children}</RichTextHeading>,
  [RichText.HEADING_6]: (children, node) => <RichTextHeading type={node.nodeType}>{children}</RichTextHeading>,
  [RichText.UL_LIST]: (children, node) => <RichTextList type={node.nodeType}>{children}</RichTextList>,
  [RichText.OL_LIST]: (children, node) => <RichTextList type={node.nodeType}>{children}</RichTextList>,
  [RichText.TABLE]: (_children, node) => <RichTextTable {...node} />,
  [RichText.LIST_ITEM]: (children) => <RichTextListItem>{children}</RichTextListItem>,
  [RichText.HYPERLINK]: (children, node) => <RichTextHyperlink data={node.data}>{children}</RichTextHyperlink>,
  [RichText.QUOTE]: (_children, node) => <HighlightedQuote {...node.data} />,
  [RichText.TEXT_FRAME]: (_children, node) => <TextFrame {...node.data} />,
  [RichText.HTML_EMBED]: (_children, node) => <HtmlEmbed {...node.data} />,
  [RichText.NEWSLETTER]: (_children, node) => <Subscription {...node.data} isEmbedded />,
  [RichText.IMAGE_EMBED]: (_children, node) => <ImageEmbed {...node.data} />,
  [RichText.LINK_LIST]: (_children, node) => <LinkList {...node.data} />,
  [RichText.INLINE_PLAYER]: (_children, node) => <InlinePlayer {...node.data} />,
  [RichText.VIDEO_COLLECTION]: (_children, node) => <VideoCollection {...node.data} hasSidebarVideoList={false} />,
  [RichText.SOCIAL_EMBED]: (_children, node) => <SocialEmbed {...node.data} />,
  [RichText.ACCORDION]: (_children, node) => <Accordion {...node.data} />,
  [RichText.RICH_TEXT]: (_children, node) =>
    documentToReactComponents(node.data).map((Item, index) => <Fragment key={index}>{Item}</Fragment>),
};

const groupAccordionNodes = (array: Node[], startIndex: number): ReactElement => {
  const children = [];
  let i = startIndex;

  while (i < array.length && array[i].nodeType === RichText.ACCORDION) {
    const reactNode = nodeToReactComponent(array[i]);
    if (reactNode) children.push(appendKeyToValidElement(reactNode, i));
    i++;
  }
  // Remove processed nodes from the original array
  array.splice(startIndex + 1, children.length - 1);
  return <StyledAccordion.Container key={`group-${startIndex}`}>{children}</StyledAccordion.Container>;
};

export const documentToReactComponents = (richTextDocument: RootNode): ReactNode[] => {
  if (!richTextDocument || richTextDocument.nodeType !== "document") {
    return [];
  }
  const elements = [...richTextDocument.content].map((item, index, array) =>
    item.nodeType === RichText.ACCORDION ? groupAccordionNodes(array, index) : nodeToReactComponent(item)
  );

  return elements;
};

const nodeToReactComponent = (node: Node): ReactNode => {
  const renderNode = defaultNodeRenderers as unknown as {
    [key: string]: NodeRenderer;
  };

  if (isValueNode(node)) {
    let formattedText = <>{node.value}</>;

    node.marks.forEach((mark) => {
      if (mark.type === MarkType.BOLD) {
        formattedText = <strong>{formattedText}</strong>;
      }

      if (mark.type === MarkType.ITALIC) {
        formattedText = <em>{formattedText}</em>;
      }

      if (mark.type === MarkType.UNDERLINE) {
        formattedText = <u>{formattedText}</u>;
      }
    });

    return formattedText;
  }
  if (node.nodeType === RichText.TABLE) {
    return defaultNodeRenderers[node.nodeType](undefined, node);
  }

  if (node.nodeType in renderNode) {
    const children: ReactNode = nodeListToReactComponents(node.content);
    return renderNode[node.nodeType](children, node);
  }

  console.log(node);

  return null;
};

export const nodeListToReactComponents = (nodes: Node[]): ReactNode => {
  return nodes.map(
    (node: Node, index: number): ReactNode => appendKeyToValidElement(nodeToReactComponent(node), index)
  );
};

const appendKeyToValidElement = (element: ReactNode, key: number): ReactNode => {
  if (isValidElement(element) && element.key === null) {
    return cloneElement(element, { key });
  }
  return element;
};

const isValueNode = (node: Node): node is TextNode => {
  return "value" in node;
};
