import { useEffect, useState } from "react";
import Image from "next/image";
import Tealium from "@4tn/webx-analytics";
import { styled } from "@mui/material";
import AspectRatioContainer from "@common/AspectRatioContainer";
import IconButton from "@common/Buttons/IconButton";
import Duration from "@pageContent/common/Duration";
import VideoPlayer from "@video/VideoPlayer";
import { BUTTON_SIZE, VARIANT } from "@constants/button";
import { IconEnum } from "@constants/consts";
import { colors, fonts, gradients, opacities } from "@constants/cssVariables";
import { TEALIUM_EVENT_CATEGORY, TEALIUM_EVENT_NAME } from "@constants/tealium.constants";
import { zIndex } from "@constants/zIndex";

export const VideoPlaceholder = styled(AspectRatioContainer, {
  shouldForwardProp: (prop) => prop !== "isFullWidthXs",
})<{ isFullWidthXs: boolean }>(({ theme, isFullWidthXs }) => ({
  cursor: "pointer",
  borderRadius: 8,
  [theme.breakpoints.down("md")]: {
    borderRadius: isFullWidthXs ? 0 : 8,
  },
}));

const PlayIcon = styled(IconButton)(({ theme }) => ({
  position: "absolute",
  top: "40%",
  left: "50%",
  transform: "translate(-50%, -40%)",
  margin: 0,

  [theme.breakpoints.up("md")]: {
    top: "50%",
    transform: "translate(-50%, -50%)",
    width: 52,
    height: 52,

    "div > svg": {
      width: 24,
      height: 24,
    },
  },
}));

const DurationContainer = styled(Duration)(({ theme }) => ({
  // 32px so it is bellow play button + 6px space between play button and duration
  top: "calc(40% + 38px)",
  left: "50%",
  transform: "translate(-50%, -40%)",
  opacity: opacities.opacity02,

  [theme.breakpoints.up("md")]: {
    // 38px so it is bellow play button + 8px space between play button and duration
    top: "calc(50% + 46px)",
    transform: "translate(-50%, -50%)",
  },
}));

const TitleWrapper = styled("div")(({ theme }) => ({
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  width: "100%",
  padding: theme.spacing(0, 4, 4, 4),
  [theme.breakpoints.up("md")]: {
    padding: theme.spacing(0, 6, 6, 6),
  },
}));

const Title = styled("div")(({ theme }) => ({
  font: fonts.header4,
  color: colors.neutral0,
  // styles to truncate the title when 2 lines are reached
  overflow: "hidden",
  display: "-webkit-box",
  WebkitBoxOrient: "vertical",
  WebkitLineClamp: "2",
  whiteSpace: "pre-wrap",
  [theme.breakpoints.up("md")]: {
    WebkitLineClamp: "3",
  },
}));

export const InlinePlayerImage = styled(Image, { shouldForwardProp: (prop) => prop !== "isFullWidthXs" })<{
  isFullWidthXs: boolean;
}>(({ theme, isFullWidthXs }) => ({
  objectFit: "cover",
  borderRadius: 8,
  [theme.breakpoints.down("md")]: {
    borderRadius: isFullWidthXs ? 0 : 8,
  },
}));

// created to set zIndex so player controls won't be part of gradient styling
const PlayerControlsWrapper = styled("div")({
  position: "absolute",
  width: "100%",
  height: "100%",
  zIndex: zIndex.base,
});

const Gradient = styled("div", { shouldForwardProp: (prop) => prop !== "isFullWidthXs" })<{
  isFullWidthXs: boolean;
}>(({ theme, isFullWidthXs }) => ({
  zIndex: zIndex.base,
  height: "100%",
  width: "100%",
  position: "absolute",
  background: gradients.fadeBlackBottomTop,
  borderRadius: 8,
  [theme.breakpoints.down("md")]: {
    borderRadius: isFullWidthXs ? 0 : 8,
  },
}));

export const playButtonTestId = "inline-player-play-button";

interface InlinePlayerProps {
  videoGuid?: string;
  title: string;
  duration: number;
  imageUrl?: string;
  playerLoadedInitially?: boolean;
  isFullWidthXs?: boolean;
  enableFloating?: boolean;
}
const InlinePlayer: React.FC<InlinePlayerProps> = ({
  title,
  videoGuid,
  duration,
  imageUrl,
  playerLoadedInitially,
  isFullWidthXs,
  enableFloating = false,
}) => {
  const [playerLoaded, setPlayerLoaded] = useState(playerLoadedInitially || false);

  useEffect(() => {
    setPlayerLoaded(!!playerLoadedInitially);
  }, [playerLoadedInitially]);

  const handleRemove = () => {
    setPlayerLoaded(false);
  };

  const handlePlayClick = () => {
    Tealium.link({
      event_name: TEALIUM_EVENT_NAME.CONTENT_LISTING_PLAY,
      event_category: TEALIUM_EVENT_CATEGORY.PLAYER,
      media_id: videoGuid || null,
    });
    setPlayerLoaded(true);
  };

  if (!videoGuid) return null;

  if (playerLoaded && videoGuid)
    return (
      <VideoPlayer
        guid={videoGuid}
        image={imageUrl}
        onRemove={handleRemove}
        isFullWidthXs={isFullWidthXs}
        enableFloating={enableFloating}
      />
    );

  return (
    <VideoPlaceholder aspectRatio="16:9" isFullWidthXs={!!isFullWidthXs} onClick={handlePlayClick}>
      <Gradient isFullWidthXs={!!isFullWidthXs} />
      {imageUrl && (
        <InlinePlayerImage
          src={imageUrl}
          alt={title}
          fill
          isFullWidthXs={!!isFullWidthXs}
          sizes="(max-width: 1200px) 100vw, 80vw"
        />
      )}
      <PlayerControlsWrapper>
        <PlayIcon
          icon={IconEnum.PLAY_ICON_FILLED}
          size={BUTTON_SIZE.MEDIUM}
          variant={VARIANT.PRIMARY}
          color={colors.neutral0}
          data-testid={playButtonTestId}
        />
        <DurationContainer duration={duration} />
        <TitleWrapper>
          <Title>{title}</Title>
        </TitleWrapper>
      </PlayerControlsWrapper>
    </VideoPlaceholder>
  );
};

export default InlinePlayer;
