import React from "react";
import Link from "next/link";
import Tealium from "@4tn/webx-analytics";
import { styled } from "@mui/material/styles";
import IconButton from "@common/Buttons/IconButton";
import { BUTTON_SIZE, VARIANT } from "@constants/button";
import { IconEnum } from "@constants/consts";
import { colors, fonts } from "@constants/cssVariables";
import { SocialMediaEnum } from "@constants/socialMedia";
import { TEALIUM_EVENT_CATEGORY, TEALIUM_EVENT_NAME } from "@constants/tealium.constants";

type SocialShareItemType = {
  name: SocialMediaEnum;
  accessibilityLabel: string;
  icon: IconEnum;
  link?: string;
};

const trackClick = (eventLabel: string) => {
  Tealium.link({
    event_category: TEALIUM_EVENT_CATEGORY.SOCIAL_MEDIA_LINK,
    event_name: TEALIUM_EVENT_NAME.OUTBOUND_CLICK,
    event_label: eventLabel,
  });
};

const SocialMediaContainer = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  gap: theme.spacing(4),
}));

const SocialMediaTitle = styled("p")(() => ({
  font: fonts.header5,
  color: colors.neutral0,
}));

const SocialMediaItemsContainer = styled("div")(({ theme }) => ({
  display: "flex",
  gap: theme.spacing(4),

  [theme.breakpoints.up("md")]: {
    gap: theme.spacing(3),
  },
}));

const getSocialMediaItems = ({
  instagram,
  youtube,
  facebook,
  x,
}: Omit<SocialMedia, "title">): SocialShareItemType[] => [
  {
    name: SocialMediaEnum.INSTAGRAM,
    accessibilityLabel: "Instagram",
    icon: IconEnum.INSTAGRAM,
    link: instagram,
  },
  {
    name: SocialMediaEnum.YOUTUBE,
    accessibilityLabel: "Youtube",
    icon: IconEnum.YOUTUBE,
    link: youtube,
  },
  {
    name: SocialMediaEnum.FACEBOOK,
    accessibilityLabel: "Facebook",
    icon: IconEnum.FACEBOOK,
    link: facebook,
  },
  {
    name: SocialMediaEnum.X,
    accessibilityLabel: "X",
    icon: IconEnum.TWITTER,
    link: x,
  },
];

const SocialMedia: React.FC<SocialMedia> = ({ title, instagram, youtube, facebook, x }) => {
  const socialMediaItems = getSocialMediaItems({ instagram, youtube, facebook, x });

  return (
    <SocialMediaContainer>
      <SocialMediaTitle>{title}</SocialMediaTitle>
      <SocialMediaItemsContainer>
        {socialMediaItems.map((item) => (
          <React.Fragment key={item.name}>
            {item.link && (
              <Link href={item.link} target="_blank" onClick={() => item.link && trackClick(item.link)}>
                <IconButton
                  icon={item.icon}
                  size={BUTTON_SIZE.MEDIUM}
                  variant={VARIANT.PRIMARY}
                  color={colors.neutral0}
                  aria-label={item.accessibilityLabel}
                />
              </Link>
            )}
          </React.Fragment>
        ))}
      </SocialMediaItemsContainer>
    </SocialMediaContainer>
  );
};

export default SocialMedia;
