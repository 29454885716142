export enum SocialEmbedItemType {
  FACEBOOK = "facebook",
  INSTAGRAM = "instagram",
  TWITTER = "x",
  TIKTOK = "tiktok",
}

export const socialMediaConsentCategory = "C0004";

export const noConsentSocialEmbedMessageTitle = "Mis je iets?";

export const capitalize = (input: string) => (input ? input.charAt(0).toUpperCase() + input.slice(1) : "");

const getCapitalizedEmbedType = (embedType: SocialEmbedItemType): string => {
  if (embedType === SocialEmbedItemType.TIKTOK) return "TikTok";
  return capitalize(embedType);
};

export const getNoConsentSocialEmbedMessageText = (embedType: SocialEmbedItemType) => {
  if (!embedType) {
    return "";
  }

  return `Helaas kunnen wij deze ${getCapitalizedEmbedType(
    embedType
  )} post niet laden omdat je hier geen toestemming voor hebt gegeven. Accepteer de Marketing en Social Media cookies om de post alsnog te laden.`;
};

export const noConsentSocialEmbedButtonText = "Cookie-instellingen aanpassen";
