import getConfig from "next/config";
import { print, stripIgnoredCharacters } from "graphql";
import gql from "graphql-tag";
import type { DocumentNode } from "graphql/language/ast";
import getHostConfig from "@utils/common/getHostConfig";
import { Episode } from "./dataModels";

const {
  publicRuntimeConfig: { videoApiEndpoint },
} = getConfig();

const GetVideoQuery = gql`
  query GetVideoQuery($guid: [String]) {
    programs(guid: $guid) {
      items {
        guid
        metadata
        availableRegion
        media {
          type
          availabilityState
        }
        tracks {
          label
          kind
          file
        }
        sources {
          file
          type
          drm
        }
      }
    }
  }
`;

const EpisodeQuery = gql`
  query EpisodeQuery($seriesId: String, $guid: [String], $limit: Int, $skip: Int, $availableWithinHours: Int) {
    programs(
      seriesId: $seriesId
      guid: $guid
      programTypes: EPISODE
      limit: $limit
      skip: $skip
      availableWithinHours: $availableWithinHours
    ) {
      totalResults
      items {
        guid
        tvSeasonEpisodeNumber
        added
        updated
        season {
          title
          seasonNumber
        }
        availableRegion
        title
        description
        duration
        imageMedia {
          url
          label
        }
        series {
          title
          slug
          title
          description
          displayGenre
          imageMedia {
            url
            label
          }
        }
        media {
          airedDateTime
          availabilityState
        }
        sources {
          file
          type
        }
        tvSeasonId
      }
    }
  }
`;

const EpisodesBySeasonIdQuery = gql`
  query EpisodesBySeasonIdQuery($tvSeasonId: String, $skip: Int, $limit: Int) {
    programs(tvSeasonId: $tvSeasonId, programTypes: EPISODE, skip: $skip, limit: $limit) {
      totalResults
      items {
        guid
        tvSeasonEpisodeNumber
        series {
          slug
        }
        imageMedia {
          url
          label
        }
        media {
          airedDateTime
          availabilityState
        }
        sources {
          file
          type
        }
        duration
      }
    }
  }
`;

const SeasonsQuery = gql`
  query SeasonsQuery($slug: String) {
    programs(slugs: [$slug], programTypes: SERIES) {
      items {
        seriesTvSeasons {
          title
          seasonNumber
          id
          guid
        }
      }
    }
  }
`;

const AVAILABLE_SEASON_PREFIX = "program_";

const AvailableSeasonsQuery = (seasons: Array<{ value: string }>) => {
  return gql`query AvailableSeasonsQuery{
    ${seasons.map(
      (
        season,
        index
      ) => `${AVAILABLE_SEASON_PREFIX}${index}: programs(tvSeasonId: "${season.value}", skip: 0, limit: 1, programTypes: EPISODE) {
      totalResults
    }`
    )}
  }`;
};

export default async function fetchPrograms(
  query: DocumentNode,
  variables: {
    guid?: string | string[];
    slug?: string;
    availableWithinHours?: number;
    seriesId?: string;
    tvSeasonId?: string;
    skip?: number;
    limit?: number;
  }
): Promise<{ items: Program[]; totalResults?: number }> {
  const queryParams = new URLSearchParams({
    query: stripIgnoredCharacters(print(query)),
    variables: JSON.stringify(variables),
  });

  const response = await fetch(`${videoApiEndpoint}?${queryParams.toString()}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  });
  const { data, errors } = await response.json();

  if (errors?.length) {
    // Todo: log errors
    return { items: [] };
  }

  return { items: data.programs?.items || [], totalResults: data.programs?.totalResults };
}

export async function fetchVideo(guid: string): Promise<Program | null> {
  return fetchPrograms(GetVideoQuery, { guid }).then(({ items }) => (items.length ? items[0] : null));
}

export async function fetchLatestEpisode(seriesSlug: string | string[]): Promise<Episode | null> {
  const { origin, allowedSeries } = getHostConfig();

  const match = allowedSeries.find(({ slug }) => slug === seriesSlug);
  if (!match) return null;
  return fetchPrograms(EpisodeQuery, { seriesId: match.id, limit: 1, availableWithinHours: 1 }).then(({ items }) => {
    if (!items.length) return null;
    const [program] = items;
    return new Episode(program, { origin, seriesTitle: match.title, includeStructuredData: true });
  });
}

export async function fetchEpisode(guid: string | string[]): Promise<Episode | null> {
  const { origin, allowedSeries } = getHostConfig();
  return fetchPrograms(EpisodeQuery, { guid }).then(({ items }) => {
    if (!items.length) return null;
    const [program] = items;
    const series = allowedSeries.find(({ slug }) => slug === program.series?.slug);
    if (!series) return null;
    return new Episode(program, { origin, seriesTitle: series.title, includeStructuredData: true });
  });
}

export async function fetchSeasons(slug: string): Promise<Array<DropdownOption>> {
  return fetchPrograms(SeasonsQuery, { slug }).then(({ items }) =>
    (items[0]?.seriesTvSeasons || []).map(({ id, title, seasonNumber }) => ({
      value: id,
      label: title || `Seizoen ${seasonNumber}`,
      isDisabled: false,
    }))
  );
}

export async function fetchEpisodesBySeasonId(
  tvSeasonId: string,
  skip: number,
  limit: number
): Promise<{
  totalResults?: number;
  items: Episode[];
}> {
  return fetchPrograms(EpisodesBySeasonIdQuery, { tvSeasonId, skip, limit }).then(({ items, totalResults }) => ({
    totalResults,
    items: items.map((item) => new Episode(item, { origin: "" })),
  }));
}

export async function fetchEpisodesBySeriesId(
  seriesId: string,
  skip: number,
  limit: number
): Promise<{
  totalResults: number;
  items: Episode[];
}> {
  return fetchPrograms(EpisodeQuery, { seriesId, skip, limit }).then(({ items, totalResults }) => ({
    totalResults: totalResults || items.length,
    items: items.map((item) => new Episode(item, { origin: "" })),
  }));
}

export async function checkForSeasonAvailability(seasons?: Array<DropdownOption>): Promise<Array<DropdownOption>> {
  if (!seasons?.length) return [];
  const query = AvailableSeasonsQuery(seasons);

  const queryParams = new URLSearchParams({
    query: stripIgnoredCharacters(print(query)),
  });

  try {
    const response = await fetch(`${videoApiEndpoint}?${queryParams.toString()}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });
    const { data } = await response.json();

    const result = seasons.map((season, index) => {
      const { totalResults } = data[`${AVAILABLE_SEASON_PREFIX}${index}`] || {};
      return {
        ...season,
        isDisabled: totalResults === 0,
      };
    });

    return result;
  } catch {
    return seasons;
  }
}
