export const zIndex = {
  base: 1,
  baseControls: 2,
  gradient: 3,
  floating: 1000,
  appBar: 1100,
  drawer: 1200,
  modal: 1300,
  dropdown: 1500,
  tooltip: 1600,
};
