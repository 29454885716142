import Image from "next/image";
import Tealium from "@4tn/webx-analytics";
import { useQuery } from "@tanstack/react-query";
import { PrimaryButton } from "@common/Buttons";
import RichText from "@pageContent/RichText";
import { AD, ROUTE_PATHS } from "@constants/consts";
import { TEALIUM_EVENT_CATEGORY, TEALIUM_EVENT_NAME } from "@constants/tealium.constants";
import { fetchByEndpoint } from "@utils/pageContent/fetchData";
import * as Styled from "./SponsoredCard.styled";

export const sponsorImageAltText = "sponsorImage";
export const logoAltText = "sponsorLogoImage";
const sponsoredCardQueryKey = ["sponsored-card"];

const trackClick = (eventLabel: string) => {
  const isArticlePage = document.location.pathname.includes(ROUTE_PATHS.ARTICLE);

  Tealium.link({
    event_category: TEALIUM_EVENT_CATEGORY.CONTENT_LISTING,
    event_name: `${TEALIUM_EVENT_NAME.CONTENT_LISTING_CLICK}_sponsored_card_${
      isArticlePage ? "article" : "overview"
    }_page`,
    event_label: eventLabel,
  });
};

const SponsoredCard: React.FC = () => {
  const { data: sponsoredCard } = useQuery<SponsoredCard | null>({
    queryKey: sponsoredCardQueryKey,
    queryFn: () => fetchByEndpoint("/api/sponsoredCard"),
  });

  if (!sponsoredCard) return null;

  return (
    <Styled.Wrapper>
      <Styled.Advert>{AD}</Styled.Advert>
      <Styled.CardContainer>
        <Styled.ImageContainer>
          <Styled.AspectRatio aspectRatio="16:9">
            <Image
              src={sponsoredCard.imageUrl}
              alt={sponsorImageAltText}
              fill
              sizes={`(max-width: 900px) 90vw, (max-width: 1440px) 25vw, 352px`}
            />
          </Styled.AspectRatio>
        </Styled.ImageContainer>
        <Styled.CardContentContainer>
          <Styled.AdTitle>{sponsoredCard.title}</Styled.AdTitle>
          <RichText richText={sponsoredCard.text} />
          <Styled.CardFooterWrapper>
            <Styled.SponsoredLogoLabelWrapper>
              <Styled.SponsoredLabel>{sponsoredCard.sponsoredLabel}</Styled.SponsoredLabel>
              <Styled.SponsoredLogoContainer>
                <Styled.SponsoredLogo alt={logoAltText} fill sizes="50px" src={sponsoredCard.logoUrl} />
              </Styled.SponsoredLogoContainer>
            </Styled.SponsoredLogoLabelWrapper>
            <PrimaryButton
              href={sponsoredCard.targetUrl.url}
              component={"a"}
              target={sponsoredCard.targetUrl.target}
              onClick={() => trackClick(sponsoredCard.targetUrl.url)}
            >
              {sponsoredCard.targetUrl.title}
            </PrimaryButton>
          </Styled.CardFooterWrapper>
        </Styled.CardContentContainer>
      </Styled.CardContainer>
    </Styled.Wrapper>
  );
};

export default SponsoredCard;
