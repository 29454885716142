import Image from "next/image";
import Link from "next/link";
import { styled } from "@mui/material";
import AspectRatioContainer from "@common/AspectRatioContainer";
import IconButton from "@common/Buttons/IconButton";
import Duration from "@pageContent/common/Duration";
import Label from "@pageContent/common/Label";
import { SCALE_ANIMATION } from "@constants/consts";
import { colors, fonts, opacities } from "@constants/cssVariables";
import { zIndex } from "@constants/zIndex";

const activeStyles = {
  "&:after": {
    content: `""`,
    position: "absolute",
    border: `3px solid ${colors.primary}`,
    borderRadius: 12,
    inset: -5,
  },
};
export const ItemContainer = styled(Link, { shouldForwardProp: (prop) => prop !== "isActive" })<{
  isActive?: boolean;
}>(({ theme, isActive }) => ({
  position: "relative",
  display: "flex",
  gap: theme.spacing(3),
  [theme.breakpoints.up("sm")]: {
    flexDirection: "column",
    gap: 0,
    ...(isActive && activeStyles),
    ...SCALE_ANIMATION,
  },

  [theme.breakpoints.down("sm")]: {
    [`${AspectRatioContainer}`]: {
      ...(isActive && activeStyles),
    },
  },
}));

export const ImageContainer = styled("div")(({ theme }) => ({
  position: "relative",
  width: 115,
  height: 76,
  minWidth: 115,
  [`${AspectRatioContainer}`]: {
    height: "100%",

    [theme.breakpoints.up("sm")]: {
      overflow: "hidden",
      borderRadius: "8px 8px 0 0",
    },
  },

  [theme.breakpoints.up("sm")]: {
    width: "100%",
    height: "auto",
  },
}));

export const ArticleImage = styled(Image)(({ theme }) => ({
  objectFit: "cover",
  borderRadius: 6,
  [theme.breakpoints.up("sm")]: {
    borderRadius: "8px 8px 0 0",
  },
}));

export const PlayIcon = styled(IconButton, { shouldForwardProp: (prop) => prop !== "hasDurationLabel" })<{
  hasDurationLabel?: boolean;
}>(({ theme, hasDurationLabel }) => ({
  position: "absolute",
  right: 8,
  bottom: 8,
  zIndex: zIndex.base,

  [theme.breakpoints.down("md")]: {
    display: hasDurationLabel ? "none" : undefined,
  },

  [theme.breakpoints.up("sm")]: {
    right: 16,
    bottom: 16,
    width: 40,
    height: 40,

    "div > svg": {
      width: 20,
      height: 20,
    },
  },
}));

export const ItemCategory = styled("div")(({ theme }) => ({
  display: "none",
  [theme.breakpoints.up("sm")]: {
    display: "block",
    font: fonts.category,
    color: colors.primary,
    textTransform: "uppercase",
  },
}));

export const TextContainer = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "flex-start",
  padding: theme.spacing(1, 0, 3, 0),
  [theme.breakpoints.up("sm")]: {
    alignItems: "flex-start",
    justifyContent: "space-between",
    flexDirection: "column",
    gap: theme.spacing(4),
    padding: theme.spacing(3, 4, 6, 4),
    border: `2px solid ${colors.neutral10}`,
    borderTop: "none",
    borderRadius: "0 0 8px 8px",
    height: "100%",
  },
}));

export const ItemTitle = styled("p")(({ theme }) => ({
  font: fonts.header6,
  color: colors.neutral80,
  width: "100%",
  display: "-webkit-box",
  WebkitBoxOrient: "vertical",
  WebkitLineClamp: 3,
  overflow: "hidden",
  textOverflow: "ellipsis",
  overflowWrap: "break-word",
}));

export const DateLabel = styled("p")({
  font: fonts.bodySmall,
  color: colors.neutral40,
});

export const TitleAndDateContainer = styled("div")(() => ({
  wordBreak: "break-word",
}));

export const DurationLabel = styled(Duration)(({ theme }) => ({
  zIndex: zIndex.base,
  backgroundColor: colors.primary,
  right: 8,
  bottom: 8,
  [theme.breakpoints.up("md")]: {
    left: 16,
    right: "unset",
    bottom: 16,
    padding: theme.spacing(1),
    backgroundColor: opacities.opacity02,
    svg: {
      display: "none",
    },
  },
}));

export const TextLabel = styled(Label)(({ theme }) => ({
  zIndex: zIndex.base,
  left: 2,
  bottom: 2,
  font: fonts.bodyExtraSmallStrong,
  borderRadius: "2px 2px 2px 4px",
  padding: theme.spacing(0.5, 1),
  backgroundColor: opacities.opacity02,
  [theme.breakpoints.up("sm")]: {
    padding: theme.spacing(1, 1.5),
    borderRadius: 2,
    left: 6,
    bottom: 6,
  },
}));
