export const TEALIUM_EVENT_CATEGORY = {
  HEADER: "header",
  CONTENT_LISTING: "content_listing",
  ARTICLE: "article",
  VIDEO: "video",
  MENU: "menu",
  FOOTER: "footer",
  NOT_FOUND: "404_page",
  SOCIAL_MEDIA_LINK: "socialmedia_link",
  SEARCH: "search",
  PLAYER: "player",
};

export const TEALIUM_EVENT_NAME = {
  HEADER_CLICK: "header_click",
  CONTENT_LISTING_CLICK: "content_listing_click",
  CONTENT_LISTING_MORE: "content_listing_more",
  CONTENT_LISTING_PLAY: "content_listing_play",
  SHARE_INTERACTION: "share_interaction",
  MENU_CLICK: "menu_click",
  FOOTER_CLICK: "footer_click",
  OUTBOUND_CLICK: "outbound_click",
  SEARCH_QUERY: "search_query",
  SEARCH_QUERY_404: "search_query_404",
  SEARCH_CLICK: "search_click",
  PLAYER_CLICK: "player_click",
};

export const TEALIUM_EVENT_LABEL = {
  NAVIGATION: {
    SEARCH: "search",
    HOME: "home",
  },
  NEWSLETTER_SUBSCRIBE: "aanmelden nieuwsbrief",
  HEADER_PROMO: "header_promo",
  SOCIAL_SHARE_LABELS: {
    WHATSAPP: "WhatsApp",
    EMAIL: "mail",
    FACEBOOK: "facebook",
    X: "x",
    COPY_LINK: "copy",
  },
  SEARCH_RESULTS: "zoekresultaten",
  MORE_RESULTS: "meer resultaten",
};

export const TEALIUM_PAGE_CATEGORY = {
  ARTICLE: "article",
  DYNAMIC: "overview",
  CATEGORY: "category",
  HOME_PAGE: "homepage",
  VIDEO: "video",
  SEARCH: "search",
};
