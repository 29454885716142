import { useHostConfig } from "@utils/common/getHostConfig";

const toKebabCase = (str: string) =>
  str
    .replace(/([a-z])([A-Z0-9])/g, "$1-$2") // Lowercase letter followed by uppercase letter or number
    .replace(/([0-9])([A-Z])/g, "$1-$2") // Number followed by uppercase letter
    .toLowerCase();

const CssVariables: React.FC = () => {
  const { designTokens } = useHostConfig();
  if (!designTokens) return null;
  const { colors, font, fontMd, gradients, opacities, button } = designTokens;
  const { fadePageHeaderBottomTop, fadePageHeaderLeftRight } = gradients;

  return (
    <style data-testid="cssVariables">
      {`
        :root {
          ${Object.entries(colors)
            .map(([key, value]) => `--${toKebabCase(key)}-color: ${value};`)
            .join("\n")}
          --opacity-01: ${opacities.opacity01};
          --opacity-02: ${opacities.opacity02};
          --gradient-01: ${gradients.gradient01};
          --gradient-02: ${gradients.gradient02};
          --gradient-03: ${gradients.gradient03};
          --fade-black-bottom-top: ${gradients.fadeBlackBottomTop};
          --fade-black-90-bottom-top: ${gradients.fadeBlack90BottomTop};
          --fade-primary-right: ${gradients.fadePrimaryRight};
          --fade-primary-bottom-top: ${gradients.fadePrimaryBottomTop};
          ${fadePageHeaderBottomTop && `--fade-page-header-bottom-top: ${fadePageHeaderBottomTop};`};
          --fade-primary-left-right: ${gradients.fadePrimaryLeftRight};
          ${fadePageHeaderLeftRight && `--fade-page-header-left-right: ${fadePageHeaderLeftRight};`};
          --fade-white-left: ${gradients.fadeWhiteLeft};
          --fade-white-right: ${gradients.fadeWhiteRight};
          --fade-primary-90-left:${gradients.fadePrimary90Left};
          --fade-primary-90-right:${gradients.fadePrimary90Right};
           ${Object.entries(button || {})
             .map(([key, value]) => `--button-${toKebabCase(key)}: ${value};`)
             .join("\n")}
          --font-header-1: ${font.header1};
          --font-header-2: ${font.header2};
          --font-header-3: ${font.header3};
          --font-header-4: ${font.header4};
          --font-header-5: ${font.header5};
          --font-header-6: ${font.header6};
          --font-header-7: ${font.header7};
          --font-tab: ${font.tab};
          --font-tab-strong: ${font.tabStrong};
          --font-category: ${font.category};
          --font-body-extra-small: ${font.bodyExtraSmall};
          --font-body-extra-small-strong: ${font.bodyExtraSmallStrong};
          --font-body-small: ${font.bodySmall};
          --font-body-small-strong: ${font.bodySmallStrong};
          --font-body-medium: ${font.bodyMedium};
          --font-body-medium-strong: ${font.bodyMediumStrong};
          --font-body-medium-italic: ${font.bodyMediumItalic};
          --font-body-large: ${font.bodyLarge};
          --font-body-large-strong: ${font.bodyLargeStrong};
          --font-button-small: ${font.buttonSmall};
          --font-button-medium: ${font.buttonMedium};
          --font-button-large: ${font.buttonLarge};
          --font-footer: ${font.footer};
          @media (min-width: 900px) {
            --font-header-1: ${fontMd.header1};
            --font-header-2: ${fontMd.header2};
            --font-header-3: ${fontMd.header3};
            --font-header-4: ${fontMd.header4};
            --font-header-5: ${fontMd.header5};
            --font-header-6: ${fontMd.header6};
            --font-header-7: ${fontMd.header7};
            --font-tab: ${fontMd.tab};
            --font-tab-strong: ${fontMd.tabStrong};
            --font-category: ${fontMd.category};
            --font-body-extra-small: ${fontMd.bodyExtraSmall};
            --font-body-extra-small-strong: ${fontMd.bodyExtraSmallStrong};
            --font-body-small: ${fontMd.bodySmall};
            --font-body-small-strong: ${fontMd.bodySmallStrong};
            --font-body-medium: ${fontMd.bodyMedium};
            --font-body-medium-strong: ${fontMd.bodyMediumStrong};
            --font-body-medium-italic: ${fontMd.bodyMediumItalic};
            --font-body-large: ${fontMd.bodyLarge};
            --font-body-large-strong: ${fontMd.bodyLargeStrong};
            --font-button-small: ${fontMd.buttonSmall};
            --font-button-medium: ${fontMd.buttonMedium};
            --font-button-large: ${fontMd.buttonLarge};
            --font-footer: ${fontMd.footer};
          }
        }
      `}
    </style>
  );
};

export default CssVariables;
