import React from "react";
import InfiniteCollection from "@pageContent/common/InfiniteCollection";
import useFetchLimit from "@utils/pageContent/useFetchLimit";

const collectionName = "just-in-collection";

export const justInCollectionPageSizePerViewport = {
  xs: 8,
  sm: 8,
  md: 9,
  lg: 8,
};

const JustInCollection: React.FC<CategoryBasedCollection> = ({ title, endpoint, showCategory }) => {
  const limit = useFetchLimit(justInCollectionPageSizePerViewport);

  return (
    <InfiniteCollection
      title={title}
      endpoint={endpoint}
      limit={limit}
      collectionName={collectionName}
      showCategory={showCategory}
    />
  );
};

export default JustInCollection;
