import React, { FC } from "react";
import Tealium from "@4tn/webx-analytics";
import { InfiniteData } from "@tanstack/react-query";
import AspectRatioContainer from "@common/AspectRatioContainer";
import { PrimaryButton } from "@common/Buttons";
import { ItemsContainer } from "@pageContent/common/InfiniteCollection/InfiniteCollection";
import * as InfiniteCollectionItemStyled from "@pageContent/common/InfiniteCollection/InfiniteCollectionItem/InfiniteCollectionItem.styled";
import { CollectionSkeleton } from "@pageContent/common/Skeletons/InfiniteCollectionSkeleton";
import { BUTTON_SIZE, VARIANT } from "@constants/button";
import { IconEnum, SHOW_MORE_SEARCH_RESULTS } from "@constants/consts";
import { colors } from "@constants/cssVariables";
import { TEALIUM_EVENT_CATEGORY, TEALIUM_EVENT_LABEL, TEALIUM_EVENT_NAME } from "@constants/tealium.constants";
import { ReturnFetchData } from "@utils/pageContent/fetchData";
import * as Styled from "./SearchModalResult.styled";

interface SearchResultItemsProps {
  title: string;
  searchValue?: string;
  data: InfiniteData<ReturnFetchData<PageLink>, unknown> | undefined;
  isFetchingNextPage: boolean;
  hasNextPage: boolean;
  isLoading: boolean;
  limit: number;
  fetchNextPage: () => void;
  trackClick: (position: number) => void;
}

const loadMore = (fetchNextPage: () => void) => {
  Tealium.link({
    event_name: TEALIUM_EVENT_NAME.CONTENT_LISTING_MORE,
    event_category: TEALIUM_EVENT_CATEGORY.CONTENT_LISTING,
    event_label: TEALIUM_EVENT_LABEL.MORE_RESULTS,
  });
  fetchNextPage();
};

const SearchResultItems: FC<SearchResultItemsProps> = ({
  title,
  searchValue,
  data,
  isFetchingNextPage,
  hasNextPage,
  isLoading,
  limit,
  fetchNextPage,
  trackClick,
}) => {
  return (
    <Styled.SearchContainer>
      {searchValue ? (
        <Styled.ResultsTitle>
          {title} <b>&lsquo;{searchValue}&rsquo;</b>
        </Styled.ResultsTitle>
      ) : (
        <Styled.Title>{title}</Styled.Title>
      )}
      <ItemsContainer>
        {data?.pages.map((page, index) => (
          <React.Fragment key={index}>
            {page.items?.map(
              ({ id, url, hasVideo, imageUrl, category, title: linkTitle, createdAt, contentTypeLabel }, index) => (
                <InfiniteCollectionItemStyled.ItemContainer key={id} href={url} onClick={() => trackClick(index + 1)}>
                  <InfiniteCollectionItemStyled.ImageContainer>
                    {hasVideo && (
                      <InfiniteCollectionItemStyled.PlayIcon
                        icon={IconEnum.PLAY_ICON_FILLED}
                        size={BUTTON_SIZE.EXTRA_SMALL}
                        variant={VARIANT.PRIMARY}
                        color={colors.neutral0}
                        isNonInteractive
                      />
                    )}
                    {contentTypeLabel && <InfiniteCollectionItemStyled.TextLabel text={contentTypeLabel} />}
                    <AspectRatioContainer aspectRatio="16:9">
                      <InfiniteCollectionItemStyled.ArticleImage
                        src={imageUrl}
                        alt={title}
                        fill
                        sizes="(max-width: 900px) 33vw, (max-width: 1200px) 25vw, 300px"
                      />
                    </AspectRatioContainer>
                  </InfiniteCollectionItemStyled.ImageContainer>
                  <Styled.DetailsContainer>
                    <InfiniteCollectionItemStyled.ItemTitle>{linkTitle}</InfiniteCollectionItemStyled.ItemTitle>
                    {createdAt && <Styled.ItemDate>{createdAt}</Styled.ItemDate>}
                    <InfiniteCollectionItemStyled.ItemCategory>
                      {category?.title}
                    </InfiniteCollectionItemStyled.ItemCategory>
                  </Styled.DetailsContainer>
                </InfiniteCollectionItemStyled.ItemContainer>
              )
            )}
          </React.Fragment>
        ))}
        {isFetchingNextPage && <CollectionSkeleton title={title} limit={limit} showCategory />}
      </ItemsContainer>
      {hasNextPage && (
        <PrimaryButton fullWidth disabled={isLoading || isFetchingNextPage} onClick={() => loadMore(fetchNextPage)}>
          {SHOW_MORE_SEARCH_RESULTS}
        </PrimaryButton>
      )}
    </Styled.SearchContainer>
  );
};

export default SearchResultItems;
