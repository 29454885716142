import React, { useEffect, useMemo, useRef, useState } from "react";
import { useRouter } from "next/router";
import Tealium from "@4tn/webx-analytics";
import { useQuery } from "@tanstack/react-query";
import { IconEnum } from "@constants/consts";
import { TEALIUM_EVENT_CATEGORY, TEALIUM_EVENT_NAME } from "@constants/tealium.constants";
import { useNoticeError } from "@utils/common/newRelic";
import { fetchByEndpoint } from "@utils/pageContent/fetchData";
import * as Styled from "./TabBar.styled";

const tabBarQueryKey = "tabBar";

export const RIGHT_ARROW_TEST_ID = "bar-right-arrow";
export const LEFT_ARROW_TEST_ID = "bar-left-arrow";

const trackClick = (label: string) => {
  Tealium.link({
    event_category: TEALIUM_EVENT_CATEGORY.HEADER,
    event_name: TEALIUM_EVENT_NAME.HEADER_CLICK,
    event_label: label,
  });
};

const TabBar: React.FC<{ navigation?: Navigation | null }> = ({ navigation }) => {
  const [shouldShowLeftGradient, setShouldShowLeftGradient] = useState(false);
  const [shouldShowRightGradient, setShouldShowRightGradient] = useState(false);
  const [isTabBarHidden, setIsTabBarHidden] = useState<boolean>(false);
  const scrollContainerRef = useRef<HTMLDivElement>(null);
  const router = useRouter();

  const {
    data: items,
    isError,
    error,
  } = useQuery({
    queryKey: [tabBarQueryKey],
    queryFn: () => fetchByEndpoint<Navigation>("/api/navigation/tabbar-navigation"),
    initialData: navigation,
    enabled: !navigation,
    select: (data) => data?.items,
  });

  useNoticeError(error, { queryKey: tabBarQueryKey });

  const scrollHorizontally = (direction: "left" | "right") => {
    if (!scrollContainerRef.current) return;

    const { clientWidth } = scrollContainerRef.current;
    const scrollBy = direction === "left" ? -clientWidth : clientWidth;
    scrollContainerRef.current.scrollBy({ left: scrollBy, behavior: "smooth" });
  };

  const setSideGradients = () => {
    if (!scrollContainerRef.current) return;

    const { scrollLeft, scrollWidth, clientWidth } = scrollContainerRef.current;

    setShouldShowLeftGradient(scrollLeft > 0);
    setShouldShowRightGradient(scrollLeft < scrollWidth - clientWidth - 1);
  };

  useEffect(() => {
    if (!scrollContainerRef.current) return;

    setSideGradients();
    window.addEventListener("resize", setSideGradients);
    scrollContainerRef.current.addEventListener("scroll", setSideGradients);

    return () => {
      scrollContainerRef.current?.removeEventListener("scroll", setSideGradients);
      window.removeEventListener("resize", setSideGradients);
    };
  }, [scrollContainerRef]);

  useEffect(() => {
    let scrollPosition = window.scrollY;
    const handleScroll = () => {
      setIsTabBarHidden(window.scrollY > 80 && window.scrollY >= scrollPosition);
      scrollPosition = window.scrollY;
    };
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const activeLinkIndex = useMemo(() => {
    if (!items) return -1;

    let [pathname] = router.asPath.split("?");
    do {
      const index = items.findIndex((item) => (item as NavigationLink).url === pathname);
      if (index !== -1) return index;
      pathname = pathname.substring(0, pathname.lastIndexOf("/"));
    } while (pathname);

    return -1;
  }, [router.asPath, items]);

  if (isError && !items) return null;

  return (
    <Styled.TabBarContainer isTabBarHidden={isTabBarHidden}>
      <Styled.ItemsGradientContainer>
        {shouldShowLeftGradient && (
          <Styled.LeftGradient>
            <Styled.LeftArrow
              isShown={shouldShowLeftGradient}
              data-testid={LEFT_ARROW_TEST_ID}
              onClick={() => scrollHorizontally("left")}
              icon={IconEnum.LEFT_CHEVRON}
            />
          </Styled.LeftGradient>
        )}
        <Styled.ItemsContainer ref={scrollContainerRef}>
          {items?.map((item, index) => (
            <React.Fragment key={item.id}>
              {item.type !== "submenu" && (
                <Styled.NavigationItem
                  data-text={item.title}
                  aria-current={index === activeLinkIndex}
                  key={item.id}
                  href={item.url}
                  target={item.target}
                  onClick={() => trackClick(item.title)}
                  suppressHydrationWarning={item.url.startsWith("#")}
                >
                  {item.title}
                </Styled.NavigationItem>
              )}
            </React.Fragment>
          ))}
        </Styled.ItemsContainer>
        {shouldShowRightGradient && (
          <Styled.RightGradient>
            <Styled.RightArrow
              isShown={shouldShowRightGradient}
              data-testid={RIGHT_ARROW_TEST_ID}
              onClick={() => scrollHorizontally("right")}
              icon={IconEnum.RIGHT_CHEVRON}
            />
          </Styled.RightGradient>
        )}
      </Styled.ItemsGradientContainer>
    </Styled.TabBarContainer>
  );
};

export default TabBar;
