import React from "react";
import featureTooling from "./featureTooling";

interface IFeatureState<T> {
  enabled: boolean | null;
  variables: T | null;
}

const useFeature = <T = any>(featureSlug: string): IFeatureState<T> => {
  const [state, setState] = React.useState<IFeatureState<T>>({
    enabled: featureTooling.isEnabled(featureSlug),
    variables: featureTooling.getVariables(featureSlug),
  });
  React.useEffect(() => {
    function onFeatureReset() {
      const newState: IFeatureState<T> = {
        enabled: featureTooling.isEnabled(featureSlug),
        variables: featureTooling.getVariables(featureSlug),
      };
      // Only update state when enabled has changed
      if (newState.enabled === state.enabled) return;
      setState(newState);
    }

    window.eventBus.on("featureReset", onFeatureReset);

    return () => window.eventBus.off("featureReset", onFeatureReset);
  }, [featureSlug, state.enabled]);

  return state;
};

export default useFeature;
