import { useEffect, useState } from "react";
import dynamic from "next/dynamic";
import Router from "next/router";
import Tealium from "@4tn/webx-analytics";
import { searchInputPlaceholderText } from "@constants/consts";
import { TEALIUM_EVENT_CATEGORY, TEALIUM_EVENT_LABEL, TEALIUM_EVENT_NAME } from "@constants/tealium.constants";
import { FeatureSlugs } from "@utils/common/featureTooling";
import useFeature from "@utils/common/useFeature";
import updateSearchParams from "@utils/navigation/updateSearchParams";
import BrandLogo from "./BrandLogo";
import * as Styled from "./TopBar.styled";

const Account = dynamic(() => import("@pageContent/Account"));

export const topBarHamburgerAriaLabel = "menu";
export const topBarBrandLogoAriaLabel = "home";
export const topBarSearchIconAriaLabel = "search";

const trackClick = (eventLabel: string) => {
  Tealium.link({
    event_category: TEALIUM_EVENT_CATEGORY.HEADER,
    event_name: TEALIUM_EVENT_NAME.HEADER_CLICK,
    event_label: eventLabel,
  });
};

const handleBrandLogoClick = () => {
  trackClick(TEALIUM_EVENT_LABEL.NAVIGATION.HOME);
  if (Router.pathname !== "/") {
    Router.push("/");
  }
};

const TopBar: React.FC = () => {
  const [isHamburgerMenuOpen, setIsHamburgerMenuOpen] = useState(false);
  const { enabled: talpaAuthEnabled } = useFeature(FeatureSlugs.TALPA_AUTH);

  useEffect(() => {
    const onHamburgerMenu = (data: WebXEventParams["hamburgerMenu"]) => setIsHamburgerMenuOpen(data.isOpen);
    window.eventBus.on("hamburgerMenu", onHamburgerMenu);

    return () => window.eventBus.off("hamburgerMenu", onHamburgerMenu);
  }, []);

  const handleSearchClick = () => {
    updateSearchParams("");
    trackClick(TEALIUM_EVENT_LABEL.NAVIGATION.SEARCH);
  };

  const handleHamburgerMenuClick = () => {
    window.eventBus.dispatch("hamburgerMenu", { isOpen: !isHamburgerMenuOpen });
  };

  return (
    <Styled.TopBarContainer>
      <Styled.TopBarContentWrapper>
        <Styled.HiddenAnimatedArrowIcon />
        <Styled.TopBarHamburgerMenu
          role="button"
          aria-label={topBarHamburgerAriaLabel}
          onClick={handleHamburgerMenuClick}
        />
        <BrandLogo
          role="button"
          aria-label={topBarBrandLogoAriaLabel}
          cursor="pointer"
          onClick={handleBrandLogoClick}
        />
        <Styled.TopBarSearchInput
          role="input"
          contentEditable="true"
          placeholder={searchInputPlaceholderText}
          spellCheck="false"
          onClick={handleSearchClick}
        />
        <Styled.TopBarSearchIcon role="button" aria-label={topBarSearchIconAriaLabel} onClick={handleSearchClick} />
        {talpaAuthEnabled && <Account />}
      </Styled.TopBarContentWrapper>
    </Styled.TopBarContainer>
  );
};

export default TopBar;
