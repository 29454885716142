import { FC, ReactNode, useEffect, useRef } from "react";
import { Theme, styled, useMediaQuery } from "@mui/material";
import Icon from "@common/Icons";
import { IconEnum } from "@constants/consts";
import { colors, gradients } from "@constants/cssVariables";
import { zIndex } from "@constants/zIndex";

const Arrow = styled(Icon)(({ theme }) => ({
  display: "none",
  [theme.breakpoints.up("md")]: {
    display: "flex",
    zIndex: zIndex.gradient + 1,
    borderRadius: 26,
    top: "35%",
    transform: "translateY(-30%)",
    position: "absolute",
    cursor: "pointer",
    backgroundColor: colors.neutral0,
    color: colors.primary,

    "& div": {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      width: 52,
      height: 52,
      svg: {
        width: 24,
        height: 24,
      },
    },

    "&:hover": {
      backgroundColor: colors.primary50,
      color: colors.neutral0,
    },

    "&:active": {
      backgroundColor: colors.primary120,
      color: colors.neutral0,
    },
  },
}));

export const LeftArrow = styled(Arrow)(({ theme }) => ({
  [theme.breakpoints.up("md")]: {
    left: 16,
  },
}));

export const RightArrow = styled(Arrow)(({ theme }) => ({
  [theme.breakpoints.up("md")]: {
    right: 16,
  },
}));

const GradientDiv = styled("div")(({ theme }) => ({
  display: "none",
  position: "absolute",
  [theme.breakpoints.up("md")]: {
    display: "unset",
    height: "100%",
    zIndex: zIndex.gradient,
    width: 40,
  },
}));

export const LeftGradient = styled(GradientDiv)(({ theme }) => ({
  [theme.breakpoints.up("md")]: {
    left: 0,
    background: gradients.fadeWhiteLeft,
  },
}));

export const RightGradient = styled(GradientDiv)(({ theme }) => ({
  [theme.breakpoints.up("md")]: {
    right: 0,
    background: gradients.fadeWhiteRight,
  },
}));

const SwimlaneWrapper = styled("div", { shouldForwardProp: (prop) => prop !== "showControlsOnHover" })<{
  showControlsOnHover: boolean;
}>(({ theme, showControlsOnHover }) => ({
  margin: theme.spacing(0, -4),
  position: "relative",

  [`&.${ScrollArrowClassNames.CAN_SCROLL_RIGHT}`]: {
    [`${LeftGradient}, ${LeftArrow}`]: {
      display: "none",
    },
  },

  [`&.${ScrollArrowClassNames.CAN_SCROLL_LEFT}`]: {
    [`${RightGradient}, ${RightArrow}`]: {
      display: "none",
    },
  },

  ...(showControlsOnHover && {
    [`&:not(:hover) `]: {
      [`${LeftArrow}, ${RightArrow}`]: {
        display: "none",
      },
    },
  }),

  [theme.breakpoints.up("md")]: {
    margin: "0",
  },
}));

export const SwimlaneContainer = styled("div")(() => ({
  display: "flex",
  position: "relative",
  scrollBehavior: "smooth",
  overflowX: "auto",
  overflowY: "visible",
  scrollbarWidth: "none",
  msOverflowStyle: "none",

  // for smooth scrolling on iOS devices
  WebkitOverflowScrolling: "touch",
  "&::-webkit-scrollbar": {
    display: "none",
  },
}));

const isScrollerAtEnd = (swimlaneRef: HTMLDivElement) => {
  if (swimlaneRef) {
    const maxScrollLeft = swimlaneRef.scrollWidth - swimlaneRef.clientWidth;
    return swimlaneRef.scrollLeft >= maxScrollLeft;
  }
  return false;
};

const isScrollerOnTheEdges = (swimlaneRef: HTMLDivElement) => {
  const start = swimlaneRef.scrollLeft <= 0;
  const end = isScrollerAtEnd(swimlaneRef);
  return { start, end };
};

const getItemsData = (swimlaneRef: HTMLDivElement) => {
  // swim lane item width
  const itemWidth = swimlaneRef.children[0].clientWidth;
  // -1 so that right, previously peeking element, gets shown after sliding
  const itemsShown = Math.round(swimlaneRef.clientWidth / itemWidth) - 1;
  return { itemWidth, itemsShown };
};

const getPeekingElementWidth = (swimlaneRef: HTMLDivElement) => {
  const { itemWidth, itemsShown } = getItemsData(swimlaneRef);
  // space to show peeking elements
  const peekingElementsWidth = swimlaneRef.clientWidth - itemsShown * itemWidth;
  return peekingElementsWidth / 2;
};

const onArrowClick = (swimlaneRef: HTMLDivElement | null, direction: Direction) => {
  if (swimlaneRef) {
    const children = swimlaneRef.children;
    if (!children.length) return;

    const { itemWidth, itemsShown } = getItemsData(swimlaneRef);
    let scrollStep = itemsShown * itemWidth;

    const { start, end } = isScrollerOnTheEdges(swimlaneRef);
    // if the scroller is at the edges, scroll less to get the same width for peeking elements
    if (start || end) {
      const peekingElementWidth = getPeekingElementWidth(swimlaneRef);
      scrollStep = scrollStep + itemWidth - peekingElementWidth;
    }

    if (direction === Direction.LEFT) {
      swimlaneRef.scrollLeft -= scrollStep;
    } else {
      swimlaneRef.scrollLeft += scrollStep;
    }
  }
};

const scrollToItem = (swimlaneRef: HTMLDivElement | null, index: number, isMobile: boolean) => {
  if (swimlaneRef) {
    let peekingElementWidth = getPeekingElementWidth(swimlaneRef);
    const { itemWidth } = getItemsData(swimlaneRef);
    // on mobile we overflow the article container with 16px
    const mobileOverflow = isMobile ? 16 : 0;
    const scrollPosition = itemWidth * index - peekingElementWidth - mobileOverflow;
    swimlaneRef.scrollLeft = scrollPosition;
  }
};

enum Direction {
  LEFT,
  RIGHT,
}

enum ScrollArrowClassNames {
  CAN_SCROLL_LEFT = "can-scroll-left",
  CAN_SCROLL_RIGHT = "can-scroll-right",
}

interface SwimlaneProps {
  className?: string;
  children: ReactNode;
  scrollToItemIndex: number;
  hasHoverEffect?: boolean;
}

const Swimlane: FC<SwimlaneProps> = ({ className, children, scrollToItemIndex, hasHoverEffect = false }) => {
  const swimlaneRef = useRef<HTMLDivElement>(null);
  const swimlaneWrapperRef = useRef<HTMLDivElement>(null);
  const isMobile = useMediaQuery<Theme>((theme) => theme.breakpoints.down("md"));

  useEffect(() => {
    // on component mount, scroll to the first item minus offset
    scrollToItem(swimlaneRef.current, scrollToItemIndex, isMobile);
    addArrowClasses(swimlaneRef.current);
  }, [isMobile, scrollToItemIndex]);

  const handleArrowClick = (direction: Direction) => () => onArrowClick(swimlaneRef.current, direction);

  const addArrowClasses = (target: HTMLDivElement | null) => {
    if (!target || !swimlaneWrapperRef.current) return;

    const isLeft = target.scrollLeft === 0;

    const tolerance = 1;
    const isRight = Math.abs(Math.round(target.scrollWidth - target.scrollLeft) - target.clientWidth) <= tolerance;

    swimlaneWrapperRef.current.classList.toggle(ScrollArrowClassNames.CAN_SCROLL_RIGHT, isLeft);
    swimlaneWrapperRef.current.classList.toggle(ScrollArrowClassNames.CAN_SCROLL_LEFT, isRight);
  };

  const handleScroll = (event: React.UIEvent<HTMLDivElement>) => {
    const target = event.target as HTMLDivElement;
    addArrowClasses(target);
  };

  return (
    <SwimlaneWrapper
      ref={swimlaneWrapperRef}
      className={`${className ? className : ""}`}
      showControlsOnHover={hasHoverEffect}
    >
      <LeftArrow data-testid="left-arrow" icon={IconEnum.LEFT_CHEVRON} onClick={handleArrowClick(Direction.LEFT)} />
      <RightArrow data-testid="right-arrow" icon={IconEnum.RIGHT_CHEVRON} onClick={handleArrowClick(Direction.RIGHT)} />
      <LeftGradient />
      <RightGradient />
      <SwimlaneContainer ref={swimlaneRef} onScroll={handleScroll} data-testid="vc-swim-lane">
        {children}
      </SwimlaneContainer>
    </SwimlaneWrapper>
  );
};

export default Swimlane;
