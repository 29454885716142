import { styled } from "@mui/material";
import { AnimatedArrowIcon, HamburgerIcon, SearchIcon } from "@common/Icons";
import { maxPageContentWidth } from "@constants/consts";
import { colors, fonts } from "@constants/cssVariables";
import { zIndex } from "@constants/zIndex";

export const TopBarContainer = styled("div")(({ theme }) => ({
  zIndex: zIndex.appBar,
  position: "absolute",
  top: 0,
  width: "100%",
  height: 56,
  backgroundColor: colors.header,
  padding: theme.spacing(0, 4),

  [theme.breakpoints.up("md")]: {
    height: 64,
    padding: 0,
  },
}));

export const HiddenAnimatedArrowIcon = styled(AnimatedArrowIcon)(() => ({
  visibility: "hidden",
  pointerEvents: "none",
  position: "absolute",
  top: 0,
  left: 0,
}));

export const TopBarContentWrapper = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  height: "100%",
  maxWidth: maxPageContentWidth,
  margin: "0 auto",

  [theme.breakpoints.up("md")]: {
    padding: theme.spacing(0, 8),
  },

  [theme.breakpoints.up("lg")]: {
    justifyContent: "flex-start",
    padding: theme.spacing(0, 24),
    gap: theme.spacing(6),
  },
}));

export const TopBarHamburgerMenu = styled(HamburgerIcon)(() => ({
  cursor: "pointer",
  svg: {
    display: "flex",
    width: 40,
    height: 40,
    color: colors.neutral0,
  },
}));

export const TopBarSearchInput = styled("div")(({ theme }) => ({
  display: "none",

  [theme.breakpoints.up("lg")]: {
    cursor: "text",
    display: "flex",
    alignItems: "center",
    position: "absolute",
    left: "calc(50% - 200px)",
    width: 400,
    height: 40,
    borderRadius: 100,
    padding: theme.spacing(2, 11),
    // We use rgba format here in order to apply opacity exclusively to the background color
    backgroundColor: "rgba(255,255,255, 0.2)",
    color: colors.neutral0,
    font: fonts.bodyMedium,
    outline: "none",
    ":empty:before": {
      // Allows us to use the placeholder prop for our styled div, just like we would for a native input
      content: "attr(placeholder)",
    },
  },
}));

export const TopBarSearchIcon = styled(SearchIcon)(({ theme }) => ({
  cursor: "pointer",
  svg: {
    display: "flex",
    color: colors.neutral0,
    width: 32,
    height: 32,
  },

  [theme.breakpoints.up("lg")]: {
    cursor: "text",
    position: "absolute",
    // 200px -> 50% of the search input
    // 12px -> 50% of the icon width
    left: "calc(50% - 200px + 12px)",
    svg: {
      width: 24,
      height: 24,
    },
  },
}));
