import { EventLog } from "@4tn/webx-analytics";
import { isIosChrome } from "./advertising";

export default function getMute() {
  if (!isIosChrome()) return false;

  // For iOS Chrome, we need to check whether there was a click event on the current page
  const eventLog = [...EventLog.eventLog].reverse();
  const lastPageViewIndex = eventLog.findIndex((event) => event.event_category === "pageview");

  if (lastPageViewIndex === -1) {
    // If there's no pageview event, we can't make a decision, default to false
    return false;
  }

  // Remove all events after lastPageView
  const relevantEvents = eventLog.slice(0, lastPageViewIndex);

  // Check if any of the relevant events have event_name that includes "play" or "click"
  const shouldMute = !relevantEvents.some((event) => {
    const eventName = event.event_name;
    return typeof eventName === "string" && (eventName.includes("play") || eventName.includes("click"));
  });
  return shouldMute;
}
