import { FC } from "react";
import AspectRatioContainer from "@common/AspectRatioContainer";
import { BUTTON_SIZE, VARIANT } from "@constants/button";
import { IconEnum } from "@constants/consts";
import { colors } from "@constants/cssVariables";
import trackContentClick from "@utils/common/trackContentClick";
import * as Styled from "./InfiniteCollectionItem.styled";

interface InifiniteCollectionItemProps {
  position: number;
  sectionTitle: string;
  link: PageLink;
  showCategory?: boolean;
  isActive?: boolean;
}

const InifiniteCollectionItem: FC<InifiniteCollectionItemProps> = ({
  position,
  sectionTitle,
  link,
  showCategory,
  isActive,
}) => {
  return (
    <Styled.ItemContainer
      href={link.url}
      onClick={() => trackContentClick({ label: sectionTitle, position, ...link })}
      isActive={isActive}
    >
      <Styled.ImageContainer>
        {link.hasVideo && (
          <Styled.PlayIcon
            icon={IconEnum.PLAY_ICON_FILLED}
            size={BUTTON_SIZE.EXTRA_SMALL}
            variant={VARIANT.PRIMARY}
            color={colors.neutral0}
            isNonInteractive
            hasDurationLabel={!!link.duration}
          />
        )}
        {!!link.duration && <Styled.DurationLabel icon={IconEnum.PLAY_ICON_FILLED} duration={link.duration} />}
        {link.sponsor && <Styled.TextLabel text={link.sponsor.label} />}
        <AspectRatioContainer aspectRatio="16:9">
          <Styled.ArticleImage
            src={link.imageUrl}
            alt={link.title}
            fill
            sizes={`(max-width: 900px) ${position === 1 ? `90vw` : `33vw`}, (max-width: 1200px) 33vw , 25vw`}
          />
        </AspectRatioContainer>
      </Styled.ImageContainer>
      <Styled.TextContainer>
        <Styled.TitleAndDateContainer>
          <Styled.ItemTitle>{link.title}</Styled.ItemTitle>
          {link.dateLabel && <Styled.DateLabel suppressHydrationWarning>{link.dateLabel}</Styled.DateLabel>}
        </Styled.TitleAndDateContainer>

        {link.category && showCategory && <Styled.ItemCategory>{link.category.title}</Styled.ItemCategory>}
      </Styled.TextContainer>
    </Styled.ItemContainer>
  );
};

export default InifiniteCollectionItem;
