import { Theme, useMediaQuery } from "@mui/material";
import { ScreenSizes } from "@constants/consts";

const useScreenSize = () => {
  const isSmallScreen = useMediaQuery<Theme>((theme) => theme.breakpoints.down("md"));
  const isMediumScreen = useMediaQuery<Theme>((theme) => theme.breakpoints.only("md"));
  const isLargeScreen = useMediaQuery<Theme>((theme) => theme.breakpoints.between("md", "xl"));
  const isExtraLargeScreen = useMediaQuery<Theme>((theme) => theme.breakpoints.up("lg"));

  if (isSmallScreen) return ScreenSizes.sm;
  if (isMediumScreen) return ScreenSizes.md;
  if (isLargeScreen) return ScreenSizes.lg;
  if (isExtraLargeScreen) return ScreenSizes.xl;
};

export default useScreenSize;
