import Link from "next/link";
import { BUTTON_SIZE, VARIANT } from "@constants/button";
import { IconEnum } from "@constants/consts";
import { colors } from "@constants/cssVariables";
import { getLinkProps } from "@utils/common/getLinkProps";
import trackContentClick from "@utils/common/trackContentClick";
import * as Styled from "./LinkListItem.styled";

export const playButtonTestId = "linkListItemPlayButton";
interface LinkListItemContentProps {
  title: string;
  imageUrl: string;
  hasVideo?: boolean;
  sponsor?: Sponsor;
}

interface LinkListItemProps {
  id: string;
  url: string;
  title: string;
  imageUrl: string;
  collectionTitle: string;
  category?: Category;
  createdAt?: string;
  updatedAt?: string;
  hasVideo?: boolean;
  sponsor?: Sponsor;
}

const LinkListItemContent: React.FC<LinkListItemContentProps> = ({ hasVideo, imageUrl, title, sponsor }) => (
  <>
    <Styled.LinkListItemImageContainer>
      {hasVideo && (
        <Styled.PlayIcon
          icon={IconEnum.PLAY_ICON_FILLED}
          size={BUTTON_SIZE.EXTRA_SMALL}
          variant={VARIANT.PRIMARY}
          color={colors.neutral0}
          isNonInteractive
          data-testid={playButtonTestId}
        />
      )}
      <Styled.LinkListItemImage src={imageUrl} alt={title} fill sizes="(max-width: 900px) 33vw, 10vw" />
    </Styled.LinkListItemImageContainer>
    <Styled.LinkListItemTextContentContainer>
      {sponsor && (
        <Styled.LinkListSponsoredContentWrapper>
          <Styled.LinkListSponsoredItemText>{sponsor.text}</Styled.LinkListSponsoredItemText>
          <Styled.LinkListSponsoredItemLogoContainer>
            <Styled.LinkListSponsoredItemLogo src={sponsor.imageUrl} alt={`sponsored-${title}`} sizes="50px" fill />
          </Styled.LinkListSponsoredItemLogoContainer>
        </Styled.LinkListSponsoredContentWrapper>
      )}
      <Styled.LinkListItemTitle>{title}</Styled.LinkListItemTitle>
    </Styled.LinkListItemTextContentContainer>
  </>
);

const LinkListItem: React.FC<LinkListItemProps> = ({
  url,
  title,
  imageUrl,
  collectionTitle,
  createdAt,
  updatedAt,
  category,
  hasVideo = false,
  sponsor,
}) => {
  const additionalLinkProps = getLinkProps(url);

  return (
    <Link
      href={url}
      onClick={() =>
        trackContentClick({
          label: collectionTitle,
          url,
          title,
          createdAt,
          updatedAt,
          hasVideo,
          category,
          sponsor,
        })
      }
      {...additionalLinkProps}
    >
      {sponsor && <Styled.SponsoredLabel> {sponsor.label} </Styled.SponsoredLabel>}
      <Styled.LinkListItemContentContainer isSponsoredLinkListItem={!!sponsor}>
        <LinkListItemContent hasVideo={hasVideo} imageUrl={imageUrl} title={title} sponsor={sponsor} />
      </Styled.LinkListItemContentContainer>
    </Link>
  );
};

export default LinkListItem;
