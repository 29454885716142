import { useEffect, useState } from "react";
import { DPG_PROFILE } from "@constants/consts";
import readHostConfig from "./readHostConfig";

let hostConfig: HostConfiguration | null = null;

const HOST_CONFIG_SELECTOR = "#__HOST_CONFIG__";

export const getApiKeyFromEnvString = (hostFile: string, envString: string) => {
  const keyValuePairs = envString.split(";");

  for (const pair of keyValuePairs) {
    const [key, value] = pair.split("=");
    if (key === hostFile) {
      return value;
    }
  }
};

export function prependContentAPIEndpoint(config: HostConfiguration) {
  config.searchModal.placeholderCollectionUrl = `${config.contentApiEndpoint}${config.searchModal.placeholderCollectionUrl}`;
  config.articleCollectionPanels = {
    topCollectionUrl: `${config.contentApiEndpoint}${config.articleCollectionPanels.topCollectionUrl}`,
    bottomCollectionUrl: `${config.contentApiEndpoint}${config.articleCollectionPanels.bottomCollectionUrl}`,
  };
  config.notFoundPage.collectionUrl = `${config.contentApiEndpoint}${config.notFoundPage.collectionUrl}`;
  config.noSearchResults.collectionUrl = `${config.contentApiEndpoint}${config.noSearchResults.collectionUrl}`;
}

export default function getHostConfig(outlet?: string): HostConfiguration {
  const isServer = typeof window === "undefined";
  const shouldReread = isServer && (process.env.ENV_NAME !== "prod" || process.env.MFE_NAME === "canary") && outlet;
  if (hostConfig && !shouldReread) return hostConfig;
  if (isServer) {
    const env = process.env.ENV_NAME as HostConfiguration["env"];
    const mfeName = process.env.MFE_NAME as string;

    const hostFile = (env === "prod" && mfeName !== "canary") || !outlet ? process.env.HOST_CONFIGURATION_FILE : outlet;

    if (!hostFile) return {} as HostConfiguration;

    const config = readHostConfig(hostFile);

    config.displayAdsEndpoint = `https://advertising-module${env === "prod" ? "" : "-nonprod"}.api.dpgmedia.cloud/web/${
      DPG_PROFILE[config.outletName]
    }`;

    config.origin = process.env.NODE_ENV === "production" ? `https://${process.env.HOSTNAME}` : "http://localhost:3000";
    config.dpgApiKey = `${process.env.DPG_API_KEY}`;

    if (env !== "prod" || mfeName === "canary") {
      // Todo: we need to think of a better way to update variables based on used environment
      config.contentApiEndpoint =
        mfeName === "canary"
          ? "https://content-canary.prod.webx.talpa.digital"
          : `https://content-platform.${env}.webx.talpa.digital`;
      const apiEnv = `${env === "test" ? "dev" : env}`;
      config.subscription.subscriptionApiEndpoint = config.subscription.subscriptionApiEndpoint.replace(
        ".prd.",
        `.${apiEnv}.`
      );
      config.oAuth.profileUrl = `https://talpa.mijn.account${
        mfeName === "canary" ? "-acc" : `-${apiEnv}`
      }.talpanetwork.com/profile`;
      config.oAuth.issuer = `https://api.account${mfeName === "canary" ? "-acc" : `-${apiEnv}`}.talpanetwork.com`;

      config.oneTrustId = `${process.env.WEBX_ONE_TRUST_ID}`;
      config.featureFile = config.featureFile.replace(".prod.", `.${env}.`);
      config.tealium.profile = "dummy-web-experience";
      config.tealium.environment = "dev";
      config.dpgApiKey = `${getApiKeyFromEnvString(hostFile, `${process.env.NON_PROD_DPG_API_KEYS}`)}`;
    }

    prependContentAPIEndpoint(config);
    hostConfig = {
      ...config,
      hostFile,
      mfeName,
      env,
    };
    return hostConfig;
  }
  try {
    hostConfig = JSON.parse(
      document.querySelector<HTMLScriptElement>(HOST_CONFIG_SELECTOR)?.innerHTML || ""
    ) as HostConfiguration;

    return hostConfig;
  } catch (ignore) {}

  return {} as HostConfiguration;
}

export function useHostConfig(): HostConfiguration {
  const [config, setConfig] = useState<HostConfiguration>(getHostConfig());

  useEffect(() => {
    const updateConfig = ({ hostConfig: newHostConfig }: WebXEventParams["hostConfig"]) => {
      setConfig((prevConfig) => {
        const updatedConfig = {
          ...prevConfig,
          ...newHostConfig,
        };

        hostConfig = updatedConfig;
        const configStr = JSON.stringify(updatedConfig);
        const scriptTag = document.querySelector<HTMLScriptElement>(HOST_CONFIG_SELECTOR);
        if (scriptTag) {
          scriptTag.innerHTML = configStr;
        }

        return updatedConfig;
      });
    };

    window.eventBus.on("hostConfig", updateConfig);

    return () => {
      window.eventBus.off("hostConfig", updateConfig);
    };
  }, []);

  return config;
}
