import { SearchTabs } from "@navigation/SearchModal/components/SearchModalTabBar/SearchModalTabBar";

const getHeaderAndBodyText = (currentTab: SearchTabs, headerText: string, bodyText: string) => {
  const noResultType =
    currentTab === SearchTabs.ALL
      ? { headerText: "resultaten", notFoundBodyText: "niets" }
      : currentTab === SearchTabs.ARTICLES
      ? { headerText: "artikelen", notFoundBodyText: "geen artikelen" }
      : { headerText: "video's", notFoundBodyText: "geen video's" };
  const noResultHeaderText = headerText.replace("<NO_RESULT_TYPE>", noResultType.headerText);
  const noResultBodyText = bodyText.replace("<NO_RESULT_TYPE>", noResultType.notFoundBodyText);

  return [noResultHeaderText, noResultBodyText];
};

export default getHeaderAndBodyText;
